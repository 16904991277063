import { DATA } from "./data";
import styles from "./styles.module.scss";

export const Flexible = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (key, value) => {
    console.log(value);
    onNextStep(key);
    onTrackEvent(value);
  };
  return (
    <div className={styles.container}>
      <h2>How flexible are you?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([key, value]) => (
          <div
            onClick={() => handleNextStep(key, value)}
            key={key}
            className={styles.card}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};
