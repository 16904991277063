import { StepWithBackgroundWrapper } from "src/routes/onboarding/shared";
import { DATA } from "./data";
import styles from "./styles.module.scss";
import background from "./img/bg.png";

export const WeightFluctulate = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (value, title) => {
    onNextStep(value);
    onTrackEvent?.(title);
  };
  return (
    <StepWithBackgroundWrapper
      title="How does your weight typically change?"
      background={background}
    >
      <div className={styles.cards}>
        {Object.entries(DATA).map(([value, title]) => (
          <div
            onClick={() => handleNextStep(value, title)}
            key={value}
            className={styles.card}
          >
            {title}
          </div>
        ))}
      </div>
    </StepWithBackgroundWrapper>
  );
};
