import styles from "./styles.module.scss";
import hot from "@assets/img/emoji/hot.png";
import tired from "@assets/img/emoji/tired.png";
import calm from "@assets/img/emoji/calm.png";

import thinking from "@assets/img/emoji/thinking.png";

import { SOMATIC_KEYS } from "../../../../shared";

import { IconCard } from "../../../../shared";

const data = [
  {
    value: "1",
    displayValue: "Yes, often",
    emoji: hot
  },
  {
    value: "2",
    displayValue: "Sometimes",
    emoji: tired
  },
  {
    value: "3",
    displayValue: "Rarely",
    emoji: calm
  },
  {
    value: "4",
    displayValue: "Not sure",
    emoji: thinking
  }
];
export const SciaticaPain = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (v) => {
    localStorage.setItem(SOMATIC_KEYS.emotioanllyAffected, v.value);
    onNextStep(v.value);
    onTrackEvent?.(v.displayValue);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Do you experience sciatica pain?</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCard
              onClick={() => handleNextStep(value)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
