import styles from "./styles.module.scss";
import weightLoss from "./img/weight-loss.svg";
import strength from "./img/strength.svg";
import yogaPose from "./img/yoga-pose.svg";
import meditation from "./img/meditation.svg";
import shoulders from "./img/shoulders-back.svg";
import { usePreloadImages } from "@hooks/usePreloadImages";
import { DATA as mainGoal } from "../mainGoal/data";
import { DATA as dreamBody } from "../dreamBody/data";
import { IconCard } from "@common/iconCard";

const DATA = {
  LOSE_WEIGHT: {
    displayValue: "Lose weight & tone up",
    icon: weightLoss
  },
  BUILD_MUSCLE: {
    displayValue: "Build muscle",
    icon: strength
  },
  IMPROVE_FLEXIBILITY: {
    displayValue: "Become more flexible",
    icon: yogaPose
  },
  IMPROVE_MENTAL_HEALTH: {
    displayValue: "Enhance mental health",
    icon: meditation
  },
  IMPROVE_ENDURANCE: {
    displayValue: "Develop endurance",
    icon: shoulders
  }
};

export const DesiredResult = ({ onNextStep, onTrackEvent }) => {
  usePreloadImages([...Object.values(mainGoal), ...Object.values(dreamBody)]);

  const handleNextStep = (key, value) => {
    console.log(value);
    onNextStep(key);
    onTrackEvent(value.displayValue);
  };

  return (
    <div className={styles.container}>
      <h2>What is your desired result?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([key, value]) => (
          <IconCard
            onClick={() => handleNextStep(key, value)}
            img={<img src={value.icon} alt={value} />}
            title={value.displayValue}
            key={key}
          />
        ))}
      </div>
    </div>
  );
};
