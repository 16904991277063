import { OptionsWithBackground } from "../../../../shared";
import background from "./bg.png";

const data = [
  {
    displayValue: "I fell asleep and woke up easily",
    value: "1"
  },
  {
    value: "2",
    displayValue: "I occasionally toss and turn at night"
  },
  {
    value: "3",
    displayValue: "I don’t feel rested after sleeping"
  },
  {
    value: "4",
    displayValue: "I can’t fall or stay asleap"
  }
];

export const StruggleSleep = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (v) => {
    console.log(v);
    onNextStep?.();
    localStorage.setItem("weightChange", v.value);
    onTrackEvent?.(v.displayValue);
  };
  return (
    <OptionsWithBackground
      data={data}
      title="Struggling to get a good night's sleep?"
      onClick={handleNextStep}
      background={background}
    />
  );
};
