export const CheckboxChecked = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_30_3100)">
        <g filter="url(#filter0_d_30_3100)">
          <path
            d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z"
            fill="black"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7372 9.67573C17.1104 9.26861 17.0829 8.63604 16.6757 8.26285C16.2686 7.88966 15.6361 7.91716 15.2629 8.32428L10.5359 13.481L8.76825 11.3598C8.41468 10.9355 7.78412 10.8782 7.35984 11.2318C6.93556 11.5853 6.87824 12.2159 7.2318 12.6402L9.7318 15.6402C9.91652 15.8618 10.188 15.9929 10.4764 15.9997C10.7649 16.0065 11.0422 15.8884 11.2372 15.6757L16.7372 9.67573Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_30_3100"
          x="-2"
          y="-1"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_30_3100"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_30_3100"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_30_3100">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Checkbox = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
        fill="black"
        fillOpacity="0.4"
      />
    </svg>
  );
};
