import { axiosInstance } from "../index";
import authService from "./authService";

export const UserService = {
  updateQuestions: async (data) => {
    try {
      await authService.ensureToken();
      return axiosInstance.patch("me/questions", data);
    } catch (error) {
      console.error("Failed to update questions:", error);
      throw error;
    }
  },

  userInfo: async () => {
    try {
      await authService.ensureToken();
      return axiosInstance.get("me");
    } catch (error) {
      console.error("Failed to get user info:", error);
      throw error;
    }
  },
  userSubscription: async () => {
    try {
      await authService.ensureToken();
      return axiosInstance.get("me/subscriptions/active");
    } catch (error) {
      console.error("Failed to get user subscription:", error);
      throw error;
    }
  }
};
