import { getYatX } from "@utils/getYatX";
import styles from "../styles.module.scss";

const pathD =
  "M38 200.919C71.6729 200.919 64.9092 153.029 148.5 141C211.409 131.947 211.733 84.5005 323 81.5";

export const ChartGainWeight = ({ x }) => {
  const point = getYatX(x, pathD);
  return (
    <svg
      width="343"
      height="241"
      viewBox="0 0 343 241"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_735_9840)">
        <rect width="343" height="241" fill="#F8F8F8" />
        <rect
          width="293"
          height="1"
          transform="matrix(-1 0 0 1 330 31)"
          fill="#D9D9D9"
        />
        <rect
          width="293"
          height="1"
          transform="matrix(-1 0 0 1 330 116)"
          fill="#D9D9D9"
        />
        <rect
          width="293"
          height="1"
          transform="matrix(-1 0 0 1 330 201)"
          fill="#D9D9D9"
        />

        <path
          opacity="0.2"
          d="M148.5 141C64.9092 153.029 71.6729 200.919 38 200.919H323V81.5C211.733 84.5005 211.409 131.947 148.5 141Z"
          fill="url(#paint0_linear_735_9840)"
        />
        {point && (
          <line
            x1={point?.x}
            y1={point?.y}
            x2={point?.x}
            y2="202"
            opacity="0.2"
            stroke="#171717"
            strokeWidth="1"
            strokeDasharray="5,5"
          />
        )}
        <path
          className={styles.grow}
          d={pathD}
          stroke="url(#paint1_linear_735_9840)"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M309 106L315.77 98.7782C316.166 98.3568 316.834 98.3568 317.23 98.7782L324 106L309 106Z"
          fill="#7C8AF7"
        />
        <rect x="277" y="106" width="55" height="44" rx="7" fill="#7C8AF7" />
        <g filter="url(#filter0_d_735_9840)">
          <circle
            cx="322"
            cy="81"
            r="5"
            fill="white"
            shapeRendering="crispEdges"
            className={styles.show}
          />

          <circle
            cx="322"
            cy="81"
            r="6"
            stroke="black"
            strokeOpacity="0.16"
            strokeWidth="2"
            shapeRendering="crispEdges"
            className={styles.show}
          />
        </g>
        <g filter="url(#filter0_d_735_9841)">
          <circle
            cx="35"
            cy="202"
            r="5"
            fill="white"
            shapeRendering="crispEdges"
            className={styles.show}
          />
          <circle
            cx="35"
            cy="202"
            r="6"
            stroke="black"
            strokeOpacity="0.16"
            strokeWidth="2"
            shapeRendering="crispEdges"
            className={styles.show}
          />
        </g>
        {point && (
          <g filter="url(#filter0_d_735_9842)">
            <circle
              cx={point.x}
              cy={point.y}
              r="5"
              fill="white"
              shapeRendering="crispEdges"
              className={styles.show}
            />
            <circle
              cx={point.x}
              cy={point.y}
              r="6"
              stroke="black"
              strokeOpacity="0.16"
              strokeWidth="2"
              shapeRendering="crispEdges"
              className={styles.show}
            />
          </g>
        )}
      </g>
      <defs>
        <filter
          id="filter0_d_735_9840"
          x="307"
          y="66"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_735_9840"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_735_9840"
            result="shape"
          />
        </filter>
        <linearGradient
          className={styles.show}
          id="paint0_linear_735_9840"
          x1="38"
          y1="141"
          x2="323"
          y2="141"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00503664" stopColor="#F91E1E" />
          <stop offset="0.354356" stopColor="#FFA51F" stopOpacity="0.72" />
          <stop offset="0.576395" stopColor="#F9ED2A" stopOpacity="0.64" />
          <stop offset="0.847776" stopColor="#64EC82" stopOpacity="0.32" />
          <stop offset="1" stopColor="#36E6E6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_735_9840"
          x1="38"
          y1="116.5"
          x2="323"
          y2="116.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F91E1E" />
          <stop offset="0.26" stopColor="#FFA51F" />
          <stop offset="0.465" stopColor="#F9ED2A" />
          <stop offset="0.725" stopColor="#64EC82" />
          <stop offset="1" stopColor="#36E6E6" />
        </linearGradient>
        <clipPath id="clip0_735_9840">
          <rect width="343" height="241" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
