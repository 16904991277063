import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";

export const Hero = () => {
  const navigate = useNavigate();

  const openQuestionnaire = async () => {
    navigate("/onboarding/1");
  };

  return (
    <section className={styles.hero}>
      <div className={styles.title}>
        <h1>
          Easy & Effective Workouts <br /> for your goal
        </h1>
        <button onClick={openQuestionnaire} className={styles.cta}>
          Get Started
        </button>
      </div>
    </section>
  );
};
