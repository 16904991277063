import { useEffect, useRef, useState } from "react";
import styles from "../../shared/components/paywall/styles.module.scss";

import Overweight from "./assets/img/overweight.png";
import Underweight from "./assets/img/underweight.png";
import Normal from "./assets/img/normal.png";
import Obese from "./assets/img/obese.png";
import After from "./assets/img/after.png";
import {
  FAQSection,
  Guarantee,
  Partners,
  PaywallChart,
  PersonalPlan,
  Plan,
  ResultsSlider,
  Reviews,
  Timer,
  Transform
} from "../../shared/components/paywall/components";
import { DiscountProvider } from "../../shared/components/paywall/DiscountContext";
import { ProfileCard } from "./components/profileCard";
import { Benefits } from "./components/benefits";
import { useDispatch, useSelector } from "react-redux";
import { UpsaleModal } from "../../shared/components/paywall/components/upsaleModal";
import { AdditionalDiscount } from "../../shared/components/paywall/components/additionalDiscount";
import { setActivePlan } from "src/redux/slices/plans";

const BMI_TYPE_IMAGES = {
  Normal,
  Overweight,
  Obese,
  Underweight,
  After
};

export const Paywall = () => {
  const { easyfitRetentionPlans } = useSelector((state) => state.plans);
  const dispatch = useDispatch();
  const [upsaleModalVisible, setUpsaleModalVisible] = useState(false);
  const [showTimer, setShowTimer] = useState(false);

  const faqSectionRef = useRef(null);
  const planRef = useRef(null);
  const profileCardRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (profileCardRef.current && !showTimer) {
        const rect = profileCardRef.current.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;

        if (isVisible) {
          setShowTimer(true);
          window.removeEventListener("scroll", handleScroll);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollToPlan = () => {
    planRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleCheckoutClose = () => {
    setUpsaleModalVisible(true);
    localStorage.setItem("additionalDiscount", true);
    const retentionDefaultPlan = easyfitRetentionPlans.find(
      (plan) => plan?.uiDisplayOptions?.focused
    );
    dispatch(setActivePlan(retentionDefaultPlan));
    localStorage.setItem("isTrial", false);
  };

  useEffect(() => {
    document.body.style.overflow = upsaleModalVisible ? "hidden" : "auto";
  }, [upsaleModalVisible]);

  const hasAdditionalDiscount = localStorage.getItem("additionalDiscount");

  return (
    <DiscountProvider>
      <div className={styles.layout}>
        {showTimer && (
          <Timer
            onGetMyPlanClick={handleScrollToPlan}
            onTimeEnd={() => setShowTimer(false)}
          />
        )}
        {hasAdditionalDiscount && <AdditionalDiscount />}
        {!hasAdditionalDiscount && (
          <>
            <Transform title="Fitness" images={BMI_TYPE_IMAGES} />
            <PersonalPlan />
          </>
        )}
        <div ref={planRef}>
          <Plan
            id="1"
            title="AI-optimized plan"
            isMetrics
            onCloseCheckout={handleCheckoutClose}
            additionalDiscount={hasAdditionalDiscount}
          />
        </div>
        <div ref={profileCardRef}>
          <ProfileCard />
        </div>
        <PaywallChart />
        <Plan
          id="2"
          title="Get visible results in 4 weeks!"
          onCloseCheckout={handleCheckoutClose}
          additionalDiscount={hasAdditionalDiscount}
        />
        <Benefits />
        <Partners />
        <ResultsSlider />
        <div ref={faqSectionRef}>
          <FAQSection />
        </div>
        <Reviews />
        <Plan
          id="3"
          title="Get visible results in 4 weeks!"
          onCloseCheckout={handleCheckoutClose}
          additionalDiscount={hasAdditionalDiscount}
        />
        <Guarantee />
        <footer className={styles.footer}>
          Straiton Limited | Alpha Tower, Floor 1, Office 11, <br /> Pavlou
          Nirvana- Aipeias, Limassol, 3021, Cyprus
        </footer>
      </div>
      {upsaleModalVisible && (
        <UpsaleModal
          onClose={() => setUpsaleModalVisible(false)}
          text="Personal Loss Weight plan."
        />
      )}
    </DiscountProvider>
  );
};
