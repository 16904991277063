import { Link, useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
import { DATA } from "./data";
import { useEffect, useState } from "react";
import nextImage1 from "../peopleChosen/img/18-29.png";
import nextImage2 from "../peopleChosen/img/40-49.png";
import nextImage3 from "../peopleChosen/img/50+.png";

import authService from "@api/services/authService";
import { KeyboardArrow } from "@assets/Icons";
import { usePreloadImages } from "@hooks/usePreloadImages";
import { errorToast } from "@utils/toaster";
import { Loader } from "@common/loader";

export const Initiate = ({ onNextStep, onTrackEvent }) => {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const startAuth = async () => {
      try {
        setLoaded(false);
        await authService.anonymousSignUp();
      } catch {
        errorToast({ message: "An error occurred. Please try again." });
        navigate("/");
      } finally {
        setLoaded(true);
      }
    };
    if (!localStorage.getItem("token")) {
      startAuth();
      return;
    }
    setLoaded(true);
  }, []);

  usePreloadImages([nextImage1, nextImage2, nextImage3]);

  const handleNextStep = (value) => {
    localStorage.setItem("ageStart", value);
    onNextStep?.(value);
    onTrackEvent?.(value);
  };

  return loaded ? (
    <div className={styles.container} id="breakdown">
      <h1>Personal fitness plan to lose weight</h1>
      <p className={styles.release}>According to your age</p>
      <p className={styles.quiz}>1-MIN QUIZ</p>
      <div className={styles.cards}>
        {DATA.map((item) => (
          <div
            key={item.value}
            onClick={() => handleNextStep(item.value)}
            className={styles.card}
          >
            <img src={item.image} alt={item.value} />
            <div className={styles.label}>
              Age: {item.value} <KeyboardArrow />
            </div>
          </div>
        ))}
      </div>
      <p className={styles.privacy}>
        By choosing your age and continuing you agree to&nbsp;our{" "}
        <Link to="/terms-of-use">Terms of Service</Link> |
        <Link to="/privacy-policy"> Privacy Policy</Link>.<br />
        Please review before continuing.
      </p>
    </div>
  ) : (
    <Loader />
  );
};
