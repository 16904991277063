import { OptionsWithBackground } from "../../../../shared";
import background from "./bg.png";
import unsure from "@assets/img/emoji/unsure.png";
import nice from "@assets/img/emoji/nice.png";

const data = [
  {
    displayValue: "Yes",
    value: "1",
    emoji: unsure
  },
  {
    value: "2",
    displayValue: "No",
    emoji: nice
  }
];

export const BackProblems = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (v) => {
    console.log(v);
    onNextStep?.();
    onTrackEvent?.(v.displayValue);
    localStorage.setItem("weightChange", v.value);
  };
  return (
    <OptionsWithBackground
      data={data}
      title="Do you have any serious back problems?"
      onClick={handleNextStep}
      background={background}
      hint="If you have back problems, please contact your physician before starting the EasyFit program."
    />
  );
};
