import  { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import authService from "./services/authService";
import useLocationEvent from "@hooks/useLocationEvent";

const ProtectedRoutes = () => {
  const [isAuthorized, setIsAuthorized] = useState(undefined);

  useLocationEvent()

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const user = await authService.getInfo();
        console.log("User info:", user);

        setIsAuthorized(user?.data?.emailConfirmed || false);
      } catch (error) {
        console.error("Failed to fetch user info:", error);
        setIsAuthorized(false);
      }
    };

    checkAuthorization();
  }, []);

  if (isAuthorized === undefined) {
    return null;
  }

  return isAuthorized ? <Outlet /> : <Navigate to="/auth" replace />;
};

export default ProtectedRoutes;
