import { StarIcon } from "@assets/Icons";
import styles from "./styles.module.scss";

export const FeaturesList = () => (
  <ul className={styles.featuresList}>
    <li>
      <StarIcon />
      Abs workouts according to your body type
    </li>
    <li>
      <StarIcon />
      Personalized plans to boost your results
    </li>
    <li>
      <StarIcon />
      Unique workouts to improve your sexual energy
    </li>
    <li>
      <StarIcon />
      Auto-renewal plans, cancel anytime
    </li>
  </ul>
);
