import React, { useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Steps } from "./data/steps";
import styles from "./styles.module.scss";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./fade.css";
import { UserService } from "@api/services/userService";
import { errorToast } from "@utils/toaster";
import { trackUserEvent } from "@utils/updateDataLayer";

export const CurrentStep = () => {
  const { stepIndex } = useParams();
  const navigate = useNavigate();
  const nodeRef = useRef();
  const [isProcessing, setIsProcessing] = useState(false);

  const currentStepIndex = parseInt(stepIndex, 10) - 1;
  const currentStep = Steps[currentStepIndex];
  const Component = currentStep.component;

  const handleNextStep = async (answer) => {
    if (isProcessing) return;

    try {
      setIsProcessing(true);
      const mappedKey = currentStep?.mappingKey;

      if (answer !== undefined && mappedKey) {
        await UserService.updateQuestions({ [mappedKey]: answer });
      }

      if (answer === null && mappedKey === "qqOccasion") {
        await UserService.updateQuestions({ qqOccasionDate: null });
        navigate("/prediction");
        return;
      }

      if (currentStepIndex + 1 >= Steps.length) {
        navigate("/prediction");
        return;
      }

      navigate(`/onboarding/${currentStepIndex + 2}`);
    } catch (error) {
      errorToast({ message: error.message });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleTrackEvent = (answer) => {
    trackUserEvent(answer, currentStep);
  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={currentStepIndex}
        in={true}
        nodeRef={nodeRef}
        timeout={700}
        classNames="fade-in"
      >
        <div ref={nodeRef} className={styles.step}>
          <Component
            onNextStep={handleNextStep}
            onTrackEvent={handleTrackEvent}
          />
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};
