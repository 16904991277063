import { OptionsWithBackground, SOMATIC_KEYS } from "../../../../shared";
import background from "./bg.png";

const data = [
  {
    displayValue: "Less than a year ago",
    value: "1"
  },
  {
    value: "2",
    displayValue: "1 to 2 years ago"
  },
  {
    value: "3",
    displayValue: "More than 3 years ago"
  },
  {
    value: "4",
    displayValue: "Never"
  }
];

export const BestShape = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (v) => {
    console.log(v);
    localStorage.setItem(SOMATIC_KEYS.bestShape, v.value);
    onNextStep?.();
    onTrackEvent?.(v.displayValue);
  };
  return (
    <OptionsWithBackground
      data={data}
      title="When was the last time you were in your best shape?"
      onClick={handleNextStep}
      background={background}
    />
  );
};
