import {
  DesiredResult,
  FitnessLevel,
  FocusAreas,
  PeopleChosen,
  StartJourney,
  MainGoal,
  DreamBody,
  WeightFluctulate,
  SlimDown,
  PeakShape,
  BurnFat,
  Flexible,
  Breathing,
  IssuesStruggling,
  ReduceTension,
  ExcerciseFrequency,
  Walks,
  WorkLife,
  UsualDay,
  EnergyLevels,
  WaterIntake,
  Sleep,
  Breakfast,
  Lunch,
  Dinner,
  DietTypes,
  NutritionStatements,
  PastEvents,
  Height,
  Weight,
  WeightGoal,
  Age,
  Report,
  FutureEvent,
  EventDate
} from "../questions";

export const groupTitles = [
  "My Profile",
  "Activity",
  "Lifestyle & Habits",
  "Nutrition",
  "Almost Done"
];

export const Steps = [
  {
    groupIndex: 0,
    component: StartJourney,
    screenId: "Start_age_full",
    screenTitle: "Start your fitness journey"
  },
  {
    groupIndex: 0,
    component: PeopleChosen,
    screenId: "Social_proof",
    screenTitle: "150 million people have chosen us"
  },
  {
    groupIndex: 0,
    component: DesiredResult,
    mappingKey: "qqGoal",
    screenId: "Goal",
    screenTitle: "What is your desired result?"
  },
  {
    groupIndex: 0,
    component: MainGoal,
    screenId: "Current_body",
    screenTitle: "Describe your physique"
  },
  {
    groupIndex: 0,
    component: DreamBody,
    screenId: "Target_body",
    screenTitle: "What is your 'dream body'?"
  },
  {
    groupIndex: 0,
    component: WeightFluctulate,
    mappingKey: "qqWeightFluctuation",
    screenId: "weight_fluctuate",
    screenTitle: "How does your weight fluctuate?"
  },

  {
    groupIndex: 0,
    component: PeakShape,
    screenId: "physical_shape",
    screenTitle: "When were you in your peak physical shape?"
  },
  {
    groupIndex: 1,
    component: FitnessLevel,
    mappingKey: "qqFitnessLevel",
    screenId: "fitness_level",
    screenTitle: "What is your fitness level?"
  },
  {
    groupIndex: 1,
    component: FocusAreas,
    screenId: "focus_areas",
    screenTitle: "What are your focus areas?"
  },
  {
    groupIndex: 1,
    component: BurnFat,
    screenId: "Feedback_burn_fat",
    screenTitle: "Burn fat and sculpt your whole body"
  },
  {
    groupIndex: 1,
    component: Flexible,
    mappingKey: "qqFlexibility",
    screenId: "flexible",
    screenTitle: "How flexible are you?"
  },
  {
    groupIndex: 1,
    component: Breathing,
    mappingKey: "qqStairs",
    screenId: "steady_breathing ",
    screenTitle: "Can you maintain steady breathing when taking the stairs?"
  },
  {
    groupIndex: 1,
    component: IssuesStruggling,
    screenId: "struggle_issues",
    screenTitle: "Do you struggle with any of these issues?"
  },
  {
    groupIndex: 1,
    component: ReduceTension,
    screenId: "Feedback_body_tension",
    screenTitle: "Reduce overall body tension"
  },
  {
    groupIndex: 1,
    component: ExcerciseFrequency,
    mappingKey: "qqExerciseFrequency",
    screenId: "frequently_exercise",
    screenTitle: "How frequently do you exercise?"
  },
  {
    groupIndex: 1,
    component: Walks,
    mappingKey: "qqWalksFrequency",
    screenId: "walks",
    screenTitle: "How often do you go for walks?"
  },
  {
    groupIndex: 2,
    component: WorkLife,
    mappingKey: "qqWorkLife",
    screenId: "work_life",
    screenTitle: "How does your work life look like?"
  },
  {
    groupIndex: 2,
    component: UsualDay,
    mappingKey: "qqDayRoutine",
    screenId: "usual_day",
    screenTitle: "How would you describe your usual day?"
  },
  {
    groupIndex: 2,
    component: EnergyLevels,
    mappingKey: "qqEnergyLevels",
    screenId: "energy_levels",
    screenTitle: "What about your energy levels?"
  },
  {
    groupIndex: 2,
    component: WaterIntake,
    mappingKey: "qqDailyWaterIntake",
    screenId: "water",
    screenTitle: "What is your daily water intake?"
  },
  {
    groupIndex: 2,
    component: Sleep,
    mappingKey: "qqSleep",
    screenId: "sleep",
    screenTitle: "How much sleep do you get every night?"
  },
  {
    groupIndex: 3,
    component: Breakfast,
    mappingKey: "qqBreakfastTime",
    screenId: "breakfast",
    screenTitle: "When do you typically have breakfast?"
  },
  {
    groupIndex: 3,
    component: Lunch,
    mappingKey: "qqLunchTime",
    screenId: "lunch",
    screenTitle: "How about lunch?"
  },
  {
    groupIndex: 3,
    component: Dinner,
    mappingKey: "qqDinnerTime",
    screenId: "dinner",
    screenTitle: "What time do you have dinner?"
  },
  {
    groupIndex: 3,
    component: DietTypes,
    screenId: "diet",
    screenTitle: "What type of diet do you adhere to?"
  },
  {
    groupIndex: 3,
    component: NutritionStatements,
    screenId: "statements",
    screenTitle: "Do you agree with any of these statements?"
  },
  {
    groupIndex: 4,
    component: PastEvents,
    screenId: "events",
    screenTitle:
      "Have any of the following events influenced your weight in the past several years?"
  },
  {
    groupIndex: 4,
    component: SlimDown,
    screenId: "Feedback_weight_fluctuate",
    screenTitle: "Slim down effortlessly with EasyFit"
  },
  {
    groupIndex: 4,
    component: Height,
    mappingKey: "qqHeightCm",
    screenId: "Height",
    screenTitle: "How tall are you?"
  },
  {
    groupIndex: 4,
    component: Weight,
    mappingKey: "qqWeightKg",
    screenId: "weight",
    screenTitle: "What's your current weight?"
  },
  {
    groupIndex: 4,
    component: WeightGoal,
    mappingKey: "qqGoalWeightKg",
    screenId: "weight_goal",
    screenTitle: "Great! And what's your weight goal?"
  },
  {
    groupIndex: 4,
    component: Age,
    mappingKey: "qqAge",
    screenId: "age",
    screenTitle: "What is your age?"
  },
  {
    groupIndex: 4,
    component: Report,
    screenId: "report",
    screenTitle: "Here's your fitness report"
  },
  {
    groupIndex: 4,
    component: FutureEvent,
    mappingKey: "qqOccasion",
    screenId: "occasion",
    screenTitle: "Do you have an important occasion coming up?"
  },
  {
    groupIndex: 4,
    component: EventDate,
    mappingKey: "qqOccasionDate",
    screenId: "occasion_date",
    screenTitle: "When is this occasion?"
  }
];
