import styles from "./styles.module.scss";
import muscle from "@assets/img/emoji/muscle.png";
import run from "@assets/img/emoji/run.png";
import fingerup from "@assets/img/emoji/fingerup.png";

import nails from "@assets/img/emoji/nails.png";
import crossedarms from "@assets/img/emoji/crossedarms.png";

import { SOMATIC_KEYS } from "../../../../shared";

import { IconCard } from "../../../../shared";

const data = [
  {
    value: "DAILY",
    points: "1",
    displayValue: "Everyday",
    emoji: muscle
  },
  {
    value: "DAILY",
    points: "2",
    displayValue: "A few days a week",
    emoji: run
  },
  {
    value: "WEEKLY",
    points: "3",
    displayValue: "Once a week",
    emoji: fingerup
  },
  {
    value: "MONTHLY",
    points: "4",
    displayValue: "From time to time",
    emoji: nails
  },
  {
    value: "NEVER",
    points: "5",
    displayValue: "Never",
    emoji: crossedarms
  }
];
export const OftenWorkout = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (v) => {
    localStorage.setItem(SOMATIC_KEYS.oftenWorkout, v.points);
    onNextStep(v.value);
    onTrackEvent?.(v.displayValue);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>How often do you work out?</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCard
              onClick={() => handleNextStep(value)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
