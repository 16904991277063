import styles from "./styles.module.scss";
import before from "./img/before.png";
import after from "./img/after.png";

export const Transform = () => {
  return (
    <section className={styles.transform}>
      <div className={styles.transformContent}>
        <div className={styles.title}>
          <h2>
            Get a complex solution
            <br />
            to your weight loss problem
          </h2>
          <p>
            Take control of your weight goals — get all-in-one solution to
            calculate and&nbsp;execute your plan to lose weight and see visible
            results fast.
          </p>
        </div>
        <div className={styles.nowAfter}>
          <div className={styles.header}>
            <div className={styles.headerItem}>Now</div>
            <div className={styles.headerDivider}></div>
            <div className={styles.headerItem}>
              <span>After the program</span>
            </div>
          </div>

          <div className={styles.body}>
            <div className={styles.imageWrapper}>
              <div className={styles.beforeImage}>
                <img
                  src={before}
                  alt="Before"
                  className={styles.comparisonImage}
                />
                <div className={styles.blur}></div>
              </div>
              <div className={styles.afterImage}>
                <img
                  src={after}
                  alt="After"
                  className={styles.comparisonImage}
                />
                <div className={styles.blur}></div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.cards}>
          <div className={styles.cardsWrapper}>
            <div className={styles.metric}>
              <div className={styles.metricContent}>
                <p className={styles.metricTitle}>Body fat</p>
                <p className={styles.value}>Overweight</p>
              </div>
            </div>
            <div className={styles.metric}>
              <div className={styles.metricContent}>
                <p className={styles.metricTitle}>Exercise level</p>
                <p className={styles.value}>Beginner</p>
              </div>
              <div className={styles.levelIndicators}>
                {Array.from({ length: 3 }, (_, index) => (
                  <div
                    key={index}
                    className={`${styles.indicator} ${
                      index < 1 ? styles.filled : ""
                    }`}
                  ></div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.divider}></div>
          <div className={styles.cardsWrapper}>
            <div className={styles.metric}>
              <div>
                <p className={styles.metricTitle}>Body fat</p>
                <p className={styles.value}>Normal</p>
              </div>
            </div>
            <div className={styles.metric}>
              <div>
                <p className={styles.metricTitle}>Exercise level</p>
                <p className={styles.value}>Intermediate</p>
              </div>
              <div className={styles.levelIndicators}>
                {Array.from({ length: 3 }, (_, index) => (
                  <div
                    key={index}
                    className={`${styles.indicator} ${
                      index < 2 ? styles.filled : ""
                    }`}
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
