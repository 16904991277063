import styles from "./styles.module.scss";
import { Loader } from "@common/loader";
import { useSelector } from "react-redux";

export const Transform = ({ images, title, header = "With EasyFit" }) => {
  const profile = useSelector((state) => state.user?.userInfo?.fitnessProfile)

  const getFilledIndicatorsCount = (level) => {
    switch (level) {
      case "beginner":
        return 1;
      case "intermediate":
        return 2;
      case "advanced":
        return 3;
      default:
        return 0;
    }
  };

  if (!profile) {
    return <Loader />;
  }

  const { bodyMassType, fitnessLevel } = profile;

  const filledCount = getFilledIndicatorsCount(fitnessLevel?.toLowerCase());

  return (
    <div className={styles.container}>
      <div className={styles.transform}>
        <div className={styles.header}>
          <div className={styles.headerItem}>Now</div>
          <div className={styles.headerDivider}></div>
          <div className={styles.headerItem}>
            <span>{header}</span>
          </div>
        </div>

        <div className={styles.body}>
          <div className={styles.imageWrapper}>
            <img
              src={images[bodyMassType] || images.Obese}
              alt="Before"
              className={styles.comparisonImage}
            />
            <img
              src={images.After}
              alt="After"
              className={styles.comparisonImage}
            />
          </div>
        </div>
      </div>
      <div className={styles.cards}>
        <div className={styles.cardsWrapper}>
          <div className={styles.metric}>
            <div>
              <p className={styles.title}>Body fat</p>
              <p className={styles.value}>{bodyMassType?.toLowerCase()}</p>
            </div>
          </div>
          <div className={styles.metric}>
            <div>
              <p className={styles.title}>{title} level</p>
              <p className={styles.value}>{fitnessLevel?.toLowerCase()}</p>
            </div>
            <div className={styles.levelIndicators}>
              {Array.from({ length: 3 }, (_, index) => (
                <div
                  key={index}
                  className={`${styles.indicator} ${
                    index < filledCount ? styles.filled : ""
                  }`}
                ></div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.cardsWrapper}>
          <div className={styles.metric}>
            <div>
              <p className={styles.title}>Body fat</p>
              <p className={styles.value}>Normal</p>
            </div>
          </div>
          <div className={styles.metric}>
            <div>
              <p className={styles.title}>{title} level</p>
              <p className={styles.value}>Advanced</p>
            </div>
            <div className={styles.levelIndicators}>
              {Array.from({ length: 3 }, (_, index) => (
                <div
                  key={index}
                  className={`${styles.indicator} ${styles.filled}`}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
