import { BreakdownWithImage, SOMATIC_KEYS } from "../../../../shared";
import img from "./img.png";

const paragraphs = {
  trauma:
    "Somatic exercises <strong>can help you reduce the impact of trauma</strong> and improve overall well-being.",
  reduceStress:
    "Somatic exercises <strong>can help you reduce stress</strong>, feel more energized, release negative emotions and reduce the impact of trauma.",
  releaseEmotions:
    "Somatic exercises <strong>can help you release negative emotions</strong> and reduce the impact of trauma.",
  overall:
    "Somatic exercises can strength your mind-body connection, which <strong>can help you improve overall well-being.</strong>"
};

const paragraphConditions = {
  trauma: (unresolvedEmotions, feelingstressed) =>
    feelingstressed < 3 && unresolvedEmotions < 3,
  reduceStress: (unresolvedEmotions, feelingstressed) =>
    feelingstressed < 3 && unresolvedEmotions > 2,
  releaseEmotions: (unresolvedEmotions, feelingstressed) =>
    feelingstressed > 2 && unresolvedEmotions < 3,
  overall: (unresolvedEmotions, feelingstressed) =>
    feelingstressed > 2 && unresolvedEmotions > 2
};

export const RelaseYourself = ({ onNextStep, onTrackEvent }) => {
  const unresolvedEmotions = +localStorage.getItem(
    SOMATIC_KEYS.unresolvedEmotions
  );
  const feelingStressed = +localStorage.getItem(SOMATIC_KEYS.feelingStressed);

  const handleContinue = () => {
    onNextStep?.();
    onTrackEvent?.();
  };

  const getParagraph = () => {
    const key = Object.entries(paragraphConditions).find(([, condition]) =>
      condition(unresolvedEmotions, feelingStressed)
    )[0];
    return paragraphs[key];
  };

  const paragraphText = getParagraph();

  const paragraph = [
    "Constant stress can weigh heavily on both your body and mind.",
    paragraphText
  ];
  return (
    <BreakdownWithImage
      title="Release yourself from the hold of stress"
      paragraph={paragraph}
      img={img}
      onContinue={handleContinue}
    />
  );
};
