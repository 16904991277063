import { useState } from "react";
import styles from "./styles.module.scss";
import upset from "@assets/img/emoji/upset.png";
import exclamation from "@assets/img/emoji/exclamation.png";
import hot from "@assets/img/emoji/hot.png";
import dislike from "@assets/img/emoji/dislike.png";
import angry from "@assets/img/emoji/angry.png";
import cross from "@assets/img/emoji/no.png";

import { Button } from "@common/index";
import { openModal } from "@utils/modal";
import { Modal } from "./Modal";
import { IconCard, IconCardCheckbox } from "src/routes/onboarding/shared";

const data = [
  {
    value: "Lack of motivation",
    displayValue: "Lack of motivation",
    emoji: angry
  },
  {
    value: "Didnt have a clear plan",
    displayValue: "Didnt have a clear plan",
    emoji: upset
  },
  {
    value: "Workouts too hard",
    displayValue: "Workouts too hard",
    emoji: hot
  },
  {
    value: "Bad coaching",
    displayValue: "Bad coaching",
    emoji: dislike
  },
  {
    value: "Other setback",
    displayValue: "Other setback",
    emoji: exclamation
  }
];
export const StruggleSetbacks = ({ onNextStep, onTrackEvent }) => {
  const [checked, setChecked] = useState([]);

  const handleCheck = (value) => {
    setChecked((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((item) => item !== value);
      }

      return [...prevState, value];
    });
  };

  const handleNextStep = () => {
    onNextStep?.(checked);
    onTrackEvent?.(checked);
  };

  const openThanksModal = () => {
    openModal(<Modal onContinue={handleNextStep} />);
  };

  const handleSelectNone = () => {
    setChecked([]);
    openThanksModal();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Have you struggled with any of these setbacks in the past?</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCardCheckbox
              checked={checked.includes(value.value)}
              onClick={() => handleCheck(value.value)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
          <IconCard
            title="None of these"
            img={cross}
            onClick={handleSelectNone}
          />
        </div>
      </div>
      <div className={styles.action}>
        <Button disabled={!checked.length} onClick={openThanksModal}>
          Continue
        </Button>
      </div>
    </div>
  );
};
