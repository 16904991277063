import { DATA } from "./data";
import styles from "./styles.module.scss";
import background from "./img/bg.jpg";
import { StepWithBackgroundWrapper } from "src/routes/onboarding/shared";

export const Flexible = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (key, value) => {
    onNextStep(key);
    onTrackEvent?.(value);
  };
  return (
    <StepWithBackgroundWrapper
      title="How flexible are you?"
      background={background}
    >
      <div className={styles.cards}>
        {Object.entries(DATA).map(([key, value]) => (
          <div
            onClick={() => handleNextStep(key, value)}
            key={key}
            className={styles.card}
          >
            {value}
          </div>
        ))}
      </div>
    </StepWithBackgroundWrapper>
  );
};
