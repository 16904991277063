import { DATA } from "./data";
import styles from "./styles.module.scss";

export const PeakShape = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep(value);
    onTrackEvent(value);
  };
  return (
    <div className={styles.container}>
      <h2>When were you in your peak physical shape?</h2>
      <div className={styles.cards}>
        {DATA.map((item) => (
          <div
            onClick={() => handleNextStep(item)}
            key={item}
            className={styles.card}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
