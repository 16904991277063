import {
  PeopleChosen,
  Initiate,
  MainGoal,
  TransformBody,
  HopeToAchieve,
  StruggleSetbacks,
  ChooseBodyType,
  CompositionWantToHave,
  WeightFluctulate,
  SlimDown,
  WithoutStress,
  ChooseTargetZones,
  LoadingChart,
  ChooseFocus,
  OftenWorkout,
  YouAwesome,
  TypicalDay,
  WeKnow,
  Age,
  Breakfast,
  Breathing,
  BurnFat,
  Dinner,
  DietTypes,
  EnergyLevels,
  EventDate,
  FitnessLevel,
  Flexible,
  FutureEvent,
  Height,
  IssuesStruggling,
  Lunch,
  NutritionStatements,
  PastEvents,
  PeakShape,
  ReduceTension,
  Report,
  Sleep,
  UsualDay,
  Walks,
  WaterIntake,
  Weight,
  WeightGoal,
  ReadyToLose
} from "../questions";

export const groupTitles = [
  "My Profile",
  "Activity",
  "Lifestyle & Habbits",
  "Nutrition",
  "Almost Done"
];

export const Steps = [
  {
    groupIndex: 0,
    component: Initiate,
    screenId: "Start_age_full",
    screenTitle: "Personal fitness plan to lose weight"
  },
  {
    groupIndex: 0,
    component: PeopleChosen,
    screenId: "Social_proof",
    screenTitle: "4,280,000+ users have chosen us"
  },
  {
    groupIndex: 0,
    component: MainGoal,
    headerTitle: true,
    screenId: "Main_goal",
    screenTitle: "What's your main goal?"
  },
  {
    groupIndex: 0,
    component: WeKnow,
    screenId: "Feedback_weight_goal",
    screenTitle: "We know how to make it happen!"
  },
  {
    groupIndex: 0,
    component: HopeToAchieve,
    headerTitle: true,
    screenId: "Goals",
    screenTitle: "What do you hope to achieve?"
  },

  {
    groupIndex: 0,
    component: TransformBody,
    screenId: "Feedback_weight_goal",
    screenTitle: "Transform your body with the power of mindful exercise"
  },

  {
    groupIndex: 0,
    component: ChooseBodyType,
    headerTitle: true,
    screenId: "Current_body",
    screenTitle: "Choose your current body type"
  },

  {
    groupIndex: 0,
    component: CompositionWantToHave,
    headerTitle: true,
    mappingKey: "qqGoal",
    screenId: "Target_body",
    screenTitle: "What body composition do you want to have?"
  },

  {
    groupIndex: 0,
    component: SlimDown,
    screenId: "Feedback_weight_fluctuate",
    screenTitle: "Slim down effortlessly with EasyFit"
  },
  {
    groupIndex: 0,
    component: PeakShape,
    headerTitle: true,
    screenId: "physical_shape",
    screenTitle: "When were you in your peak physical shape?"
  },
  {
    groupIndex: 1,
    component: FitnessLevel,
    mappingKey: "qqFitnessLevel",
    headerTitle: true,
    screenId: "fitness_level",
    screenTitle: "What is your fitness level?"
  },
  {
    groupIndex: 1,
    component: ChooseTargetZones,
    headerTitle: true,
    screenId: "Target_zones",
    screenTitle: "Choose your target zones"
  },
  {
    groupIndex: 1,
    component: BurnFat,
    screenId: "Feedback_target",
    screenTitle: "Burn fat and sculpt your whole body"
  },
  {
    groupIndex: 1,
    component: Flexible,
    mappingKey: "qqFlexibility",
    headerTitle: true,
    screenId: "Flexible",
    screenTitle: "How flexible are you?"
  },
  {
    groupIndex: 1,
    component: Breathing,
    mappingKey: "qqStairs",
    headerTitle: true,
    screenId: "steady_breathing",
    screenTitle: "Can you maintain steady breathing when taking the stairs?"
  },
  {
    groupIndex: 1,
    component: IssuesStruggling,
    headerTitle: true,
    screenId: "struggle",
    screenTitle: "Do you struggle with any of these issues?"
  },
  {
    groupIndex: 1,
    component: ReduceTension,
    screenId: "Feedback_Voltage reduction",
    screenTitle: "Reduce overall body tension"
  },
  {
    groupIndex: 1,
    component: StruggleSetbacks,
    headerTitle: true,
    screenId: "Setbacks_select",
    screenTitle: "Have you struggled with any of these setbacks in the past?"
  },
  {
    groupIndex: 1,
    component: WeightFluctulate,
    mappingKey: "qqWeightFluctuation",
    headerTitle: true,
    screenId: "Weight_change",
    screenTitle: "How does your weight typically change?"
  },
  {
    groupIndex: 1,
    component: ReadyToLose,
    headerTitle: true,
    screenId: "Weightloss_determination",
    screenTitle: "Are you ready to finally lose the unwanted weight?"
  },
  {
    groupIndex: 1,
    component: ChooseFocus,
    headerTitle: true,
    screenId: "Tags",
    screenTitle: "Choose your focus"
  },
  {
    groupIndex: 2,
    component: WithoutStress,
    screenId: "Feedback_determination",
    screenTitle: "Lose excess weight without added stress"
  },
  {
    groupIndex: 2,
    component: OftenWorkout,
    headerTitle: true,
    mappingKey: "qqExerciseFrequency",
    screenId: "Workouts",
    screenTitle: "How often do you work out?"
  },
  {
    groupIndex: 2,
    component: YouAwesome,
    screenId: "Feedback_workouts",
    screenTitle: "We think you're awesome!"
  },
  {
    groupIndex: 2,
    component: TypicalDay,
    headerTitle: true,
    mappingKey: "qqDayRoutine",
    screenId: "Typical_day",
    screenTitle: "How would you describe your typical day?"
  },
  {
    groupIndex: 2,
    component: Walks,
    headerTitle: true,
    mappingKey: "qqWalksFrequency",
    screenId: "walks",
    screenTitle: "How often do you go for walks?"
  },
  // {
  //   groupIndex: 2,
  //   component: UsualDay,
  //   headerTitle: true,
  //   mappingKey: 'qqDayRoutine'
  // },
  {
    groupIndex: 2,
    headerTitle: true,
    component: EnergyLevels,
    mappingKey: "qqEnergyLevels",
    screenId: "energy_levels",
    screenTitle: "What about your energy levels?"
  },
  {
    groupIndex: 2,
    component: WaterIntake,
    mappingKey: "qqDailyWaterIntake",
    headerTitle: true,
    screenId: "daily_water",
    screenTitle: "What is your daily water intake?"
  },
  {
    groupIndex: 2,
    component: Sleep,
    mappingKey: "qqSleep",
    headerTitle: true,
    screenId: "Sleep",
    screenTitle: "How much sleep do you get every night?"
  },
  {
    groupIndex: 3,
    component: Breakfast,
    mappingKey: "qqBreakfastTime",
    headerTitle: true,
    screenId: "breakfast",
    screenTitle: "When do you typically have breakfast?"
  },
  {
    groupIndex: 3,
    component: Lunch,
    mappingKey: "qqLunchTime",
    headerTitle: true,
    screenId: "lunch",
    screenTitle: "How about lunch?"
  },
  {
    groupIndex: 3,
    component: Dinner,
    mappingKey: "qqDinnerTime",
    headerTitle: true,
    screenId: "dinner",
    screenTitle: "What time do you have dinner?"
  },
  {
    groupIndex: 3,
    component: DietTypes,
    headerTitle: true,
    screenId: "diet",
    screenTitle: "What type of diet do you adhere to?"
  },
  {
    groupIndex: 3,
    component: NutritionStatements,
    headerTitle: true,
    screenId: "statements",
    screenTitle: "Do you agree with any of these statements?"
  },
  {
    groupIndex: 4,
    component: PastEvents,
    headerTitle: true,
    screenId: "several",
    screenTitle:
      "Have any of the following events influenced your weight in the past several years?"
  },

  {
    groupIndex: 4,
    component: Height,
    headerTitle: true,
    screenId: "Height",
    screenTitle: "How tall are you"
  },
  {
    groupIndex: 4,
    component: Weight,
    headerTitle: true,
    screenId: "Weight",
    screenTitle: "What's your current weight?"
  },
  {
    groupIndex: 4,
    component: WeightGoal,
    headerTitle: true,
    screenId: "Target_weight",
    screenTitle: "Great! What weight are you aiming for?"
  },
  {
    groupIndex: 4,
    component: Age,
    headerTitle: true,
    mappingKey: "qqAge",
    screenId: "age",
    screenTitle: "What is your age?"
  },
  {
    groupIndex: 4,
    component: Report,
    headerTitle: true,
    screenId: "Fitlevel_feedback",
    screenTitle: "Your current fitness level overview"
  },
  {
    groupIndex: 4,
    component: FutureEvent,
    headerTitle: true,
    mappingKey: "qqOccasion",
    screenId: "ocassion",
    screenTitle: "Do you have an important occasion coming up?"
  },
  {
    groupIndex: 4,
    component: EventDate,
    headerTitle: true,
    mappingKey: "qqOccasionDate",
    screenId: "Ocasion_date",
    screenTitle: "When is this occasion?"
  }
];
