import styles from "./styles.module.scss";
import { FAQItem } from "./item";

export const FAQSection = () => {
  const faqData = [
    {
      question: "How will this program help me achieve my goal?",
      answer:
        "Your plan is custom to you, your needs and your fitness level. We include diverse sets of exercises to target every muscle group you selected, gradually increase intensity and encourage you to stay motivated.",
    },
    {
      question: "How long will it take to see results?",
      answer:
        "Results vary depending on individual factors such as starting fitness level, diet, and consistency. Generally, noticeable improvements can be seen in a few weeks.",
    },
    {
      question: "Where do I find my program?",
      answer:
        "After the purchase your plan and all the workout sets will become available to you. Everything is already divided by day and you can start your journey at any moment!",
    },
    {
      question:
        "What if the suggested workouts are too easy or too hard for me?",
      answer:
        "Our program adapts to your progress. You can adjust the difficulty level of exercises or swap them for alternative options that better suit your current fitness level. Plus, as you advance, the workouts will gradually become more challenging to keep you improving.",
    },
    {
      question: "Won't I be bored doing the same workouts over and over again?",
      answer:
        "Not at all! Our program includes a variety of exercises to keep things fresh and engaging. You’ll get different workout routines, challenges, and progression plans to prevent monotony. Plus, we continuously update the program to make sure you stay motivated and excited about your fitness journey.",
    },
  ];

  return (
    <div className={styles.faqSection}>
      <h2 className={styles.title}>People often ask</h2>
      <p className={styles.hint}>
        Everything you need to know about the product and billing.
      </p>
      <div className={styles.faqContainer}>
        {faqData.map((item, index) => (
          <FAQItem
            key={index}
            question={item.question}
            answer={item.answer}
            defaultExpanded={index === 0}
          />
        ))}
      </div>
    </div>
  );
};
