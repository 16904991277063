import { Button } from "@common/index";
import styles from "./styles.module.scss";
import burnfat from "./img/burnfat.jpg";

export const BurnFat = ({ onNextStep, onTrackEvent }) => {
  return (
    <div id="breakdown" className={styles.container}>
      <div className={styles.content}>
        <h2>Burn fat and sculpt your whole body</h2>
        <img src={burnfat} alt="slimdown" className={styles.image} />
        <div className={styles.hints}>
          Define your waist, strengthen your core, tone up your arms, legs and
          booty.
          <br />
          <br />
          <strong>Transform your whole body at home!</strong>
        </div>
      </div>
      <Button
        onClick={() => {
          onNextStep();
          onTrackEvent();
        }}
      >
        Continue
      </Button>
    </div>
  );
};
