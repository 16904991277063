import { DATA } from "./data";
import styles from "./styles.module.scss";

export const WeightFluctulate = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (value, title) => {
    console.log(value);
    onNextStep(value);
    onTrackEvent(title);
  };
  return (
    <div className={styles.container}>
      <h2>How does your weight fluctuate?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([value, title]) => (
          <div
            onClick={() => handleNextStep(value, title)}
            key={value}
            className={styles.card}
          >
            {title}
          </div>
        ))}
      </div>
    </div>
  );
};
