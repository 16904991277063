import styles from "./styles.module.scss";

import { IconCard, IconCardCheckbox } from "../../../../shared";

import { useState } from "react";
import { Button } from "../../../../../../common";

const data = [
  {
    value: "Neck",
    displayValue: "Neck"
  },
  {
    value: "Shoulder",
    displayValue: "Shoulder"
  },
  {
    value: "Back",
    displayValue: "Back"
  },
  {
    value: "Wrist",
    displayValue: "Wrist"
  },
  {
    value: "Elbow",
    displayValue: "Elbow"
  },
  {
    value: "Hip",
    displayValue: "Hip"
  },
  {
    value: "Knee",
    displayValue: "Knee"
  },
  {
    value: "Ankle",
    displayValue: "Ankle"
  },
  {
    value: "Foot",
    displayValue: "Physical disability "
  },
  {
    value: "Other",
    displayValue: "Other"
  }
];
export const SensitiveAreas = ({ onNextStep, onTrackEvent }) => {
  const [checked, setChecked] = useState([]);

  const handleCheck = (value) => {
    setChecked((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((item) => item !== value);
      }
      return [...prevState, value];
    });
  };

  const handleNextStep = () => {
    onNextStep(checked);
    onTrackEvent?.(checked);
  };

  const handleSkip = () => {
    onNextStep();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Do you have any sensitive areas that you want to avoid?</h2>
        <div className={styles.cardsWithImage}>
          <div className={styles.cards}>
            <IconCard
              fullWidth
              active={checked.length === data.length}
              onClick={handleSkip}
              title="No, I'm all good"
            />
            {data.map((value) => (
              <IconCardCheckbox
                fullWidth
                checked={checked.includes(value.value)}
                onClick={() => handleCheck(value.value)}
                title={value.displayValue}
                key={value.value}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.action}>
        <Button disabled={!checked.length} onClick={handleNextStep}>
          Continue
        </Button>
      </div>
    </div>
  );
};
