import { formatPeriodUnit } from "@utils/formatUnit";
import { CurrencyMap } from "../../utils/getPlanDetails";
import { PlanCheckbox } from "../svg";
import styles from "./styles.module.scss";

export const PlanItem = ({
  trialPeriodDuration,
  trialPeriodUnit,
  billingPeriodDuration,
  billingPeriodUnit,
  trialPriceAmount,
  trialNoDiscountPriceAmount,
  trialNoDiscountDailyPriceAmount,
  trialDailyPriceAmount,
  title,
  priceOld,
  priceNew,
  perDay,
  perDayOld,
  planHeader,
  checked,
  onClick,
  currency,
  isDiscounted
}) => {
  const isTrial = trialPeriodDuration !== 0;



  const planTitle = isTrial
    ? `${formatPeriodUnit(trialPeriodDuration, trialPeriodUnit)} Trial`
    : `${formatPeriodUnit(billingPeriodDuration, billingPeriodUnit)} Plan`;
  const finalPriceOld = isTrial ? trialNoDiscountPriceAmount : priceOld;
  const finalPriceNew = isTrial ? trialPriceAmount : priceNew;
  const finalPerDayOld = isTrial ? trialNoDiscountDailyPriceAmount : perDayOld;
  const finalPerDayNew = isTrial ? trialDailyPriceAmount : perDay;

  return (
    <div
      className={
        checked ? `${styles.plan} ${styles.checked}` : `${styles.plan}`
      }
      onClick={onClick}
    >
      <div className={styles.container}>
        {planHeader && <div className={styles.planHeader}>{planHeader}</div>}
        <div className={styles.planContent}>
          <div className={styles.price}>
            <PlanCheckbox checked={checked} />
            <div className={styles.priceText}>
              <h3>{planTitle}</h3>
              <p>
                {isDiscounted ? (
                  <>
                    <span className={styles.priceOld}>
                      {CurrencyMap[currency]}
                      {finalPriceOld}
                    </span>{" "}
                    <span>→</span> {CurrencyMap[currency]}
                    {finalPriceNew}
                  </>
                ) : (
                  <>
                    {CurrencyMap[currency]}
                    {finalPriceOld}
                  </>
                )}
              </p>
              {isTrial && (
                <div className={styles.trialPlanTitle}>
                  {title.replace("w/ Trial", "")}
                </div>
              )}
            </div>
          </div>
          <div className={styles.pricePerDay}>
            {isDiscounted && (
              <p className={styles.perDayOld}>
                {CurrencyMap[currency]}
                <span className={styles.strikethrough}>{finalPerDayOld}</span>
              </p>
            )}
            <p>
              {CurrencyMap[currency]}
              {isDiscounted ? finalPerDayNew : finalPerDayOld}
            </p>

            <p className={styles.perDay}>per day</p>
          </div>
        </div>
      </div>
    </div>
  );
};
