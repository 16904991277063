import { OptionsWithBackground } from "../../../../shared";
import background from "./bg.png";
import fire from "@assets/img/emoji/fire.png";
import muscle from "@assets/img/emoji/muscle.png";

const data = [
  {
    displayValue: "Lose weight",
    value: "LOSE_WEIGHT",
    emoji: fire
  },
  {
    value: "MAINTAIN_WEIGHT",
    displayValue: "Maintain weight and get fit",
    emoji: muscle
  }
];

export const MainGoal = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (v) => {
    console.log(v);
    onNextStep?.();
    onTrackEvent?.(v.displayValue);
    localStorage.setItem("mainGoal", v.value);
  };
  return (
    <OptionsWithBackground
      data={data}
      title="What's your main goal?"
      onClick={handleNextStep}
      background={background}
    />
  );
};
