import styles from "./styles.module.scss";
import { Link, useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.cta}>
          <h2>Let's get started on something great</h2>
          <p>
            Join over 1,000,000+ users who are already transforming with
            EasyFit.
          </p>
          <button
            onClick={() => navigate("/onboarding/1")}
            className={styles.getStarted}
          >
            GET STARTED
          </button>
        </div>

        <div className={styles.bottom}>
          <div className={styles.copyright}>
            © 2025 EasyFit. All rights reserved.
          </div>
          <div className={styles.links}>
            <Link to="/terms-of-use">Terms</Link>
            <Link to="/privacy-policy">Privacy</Link>
            <Link to="/cookies">Cookies</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
