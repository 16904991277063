export const useSortedPlans = (plans) => {
  return [...plans].sort((a, b) => {
    if (a.products.length > b.products.length) return -1;
    if (a.products.length < b.products.length) return 1;

    if (a.products.length === 1) {
      if (a.products.includes("SIX_PACK") && b.products.includes("KEGEL"))
        return -1;
      if (a.products.includes("KEGEL") && b.products.includes("SIX_PACK"))
        return 1;
    }

    return 0;
  });
};
