import { OptionsWithBackground, SOMATIC_KEYS } from "../../../../shared";
import background from "./bg.png";

const data = [
  {
    displayValue: "I gain weight fast but lose it slowly",
    value: "STRUGGLE_WITH_WEIGHT_LOSS"
  },
  {
    displayValue: "I gain and lose weight easily",
    value: "EASY_WEIGHT_GAIN_AND_LOSS"
  },
  {
    displayValue: "I struggle to gain weight or muscle",
    value: "STRUGGLE_WITH_WEIGHT_GAIN"
  }
];

export const HowWeightChange = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (v) => {
    onNextStep(v.value);
    onTrackEvent?.(v.displayValue);
    localStorage.setItem(SOMATIC_KEYS.weightChange, v.value);
  };
  return (
    <OptionsWithBackground
      data={data}
      title="How does your weight typically change?"
      onClick={handleNextStep}
      background={background}
    />
  );
};
