import styles from './styles.module.scss';

export const StepWithBackgroundWrapper = ({ title, background, children }) => {
  return (
    <div className={styles.container}>
      <h2>{title}</h2>
      <div className={styles.image}>
        <img src={background} alt="bg" />
      </div>
      {children}
    </div>
  );
};
