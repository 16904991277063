import styles from "./styles.module.scss";
import breakfast from "./img/breakfast.jpg";

const DATA = {
  VERY_EARLY: "Between 6 and 8 am",
  EARLY: "Between 8 and 10 am",
  LATE: "Between 10 am and noon",
  NO_BREAKFAST: "I usually skip breakfast"
};

export const Breakfast = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (key, value) => {
    console.log(value);
    onNextStep(key);
    onTrackEvent(value);
  };

  return (
    <div className={styles.container}>
      <h2>When do you typically have breakfast?</h2>
      <img src={breakfast} alt="breakfast" className={styles.image} />
      <div className={styles.cards}>
        {Object.entries(DATA).map(([key, value]) => (
          <div
            onClick={() => handleNextStep(key, value)}
            className={styles.card}
            key={key}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};
