import {} from "@stripe/react-stripe-js";
import styles from "./styles.module.scss";
import { useStripeElements } from "../../stripeElementsContext";

const StripeCardElements = ({ onChange, errors, isSubmitted }) => {
  const { CardNumberElement, CardExpiryElement, CardCvcElement } =
    useStripeElements();

  return (
    <div className={styles.inputs}>
      <div
        className={`${styles.cardNumberElement} ${
          errors?.cardNumber ? styles.error : ""
        } ${isSubmitted ? styles.shakeOnSubmit : ""}`}
      >
        <CardNumberElement
          className={styles.cardInput}
          onChange={onChange}
          options={{
            placeholder: "XXXX XXXX XXXX XXXX",
          }}
        />
      </div>

      <div className={styles.splitFields}>
        <div
          className={`${styles.expiryDate} ${
            errors?.cardExpiry ? styles.error : ""
          } ${isSubmitted ? styles.shakeOnSubmit : ""}`}
        >
          <CardExpiryElement
            className={styles.inputField}
            onChange={onChange}
            options={{
              placeholder: "MM/YY",
            }}
          />
        </div>

        <div
          className={`${styles.cvv} ${errors?.cardCvc ? styles.error : ""} ${
            isSubmitted ? styles.shakeOnSubmit : ""
          }`}
        >
          <CardCvcElement
            className={styles.inputField}
            onChange={onChange}
            options={{
              placeholder: "CVV",
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default StripeCardElements;
