import styles from "./styles.module.scss";

import { IconCard } from "../../../../shared";

import { useState } from "react";
import { Button } from "../../../../../../common";

const data = [
  {
    value: "Reduce anxiety",
    displayValue: "Reduce anxiety"
  },
  {
    value: "Body sculpting",
    displayValue: "Body sculpting"
  },
  {
    value: "Fat Burn",
    displayValue: "Fat Burn"
  },
  {
    value: "Libido Boost",
    displayValue: "Libido Boost"
  },
  {
    value: "Face Lifting",
    displayValue: "Face Lifting"
  },
  {
    value: "Stress Reduction",
    displayValue: "Stress Reduction"
  },
  {
    value: "Flexibility",
    displayValue: "Flexibility"
  },
  {
    value: "Release stored emotions",
    displayValue: "Release stored emotions"
  },
  {
    value: "Energy Boost",
    displayValue: "Energy Boost"
  }
];
export const ChooseFocus = ({ onNextStep, onTrackEvent }) => {
  const [checked, setChecked] = useState([]);

  const handleCheck = (v) => {
    const { value } = v;
    setChecked((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((item) => item !== value);
      }
      return [...prevState, value];
    });
  };

  const handleNextStep = () => {
    onNextStep?.(checked);
    onTrackEvent?.(checked);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Choose your focus</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCard
              active={checked.includes(value.value)}
              onClick={() => handleCheck(value)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
      <div className={styles.action}>
        <Button disabled={!checked.length} onClick={handleNextStep}>
          Continue
        </Button>
      </div>
    </div>
  );
};
