import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Header } from "./components/header";
import { Stepper } from "./components/stepper/index";
import { CountdownTimer } from "./components/timer";
import { Title } from "./components/title";
import { useDispatch, useSelector } from "react-redux";
import { getAgreementTextUpsale } from "../paywall/components/plan/utils/getPlanDetails";
import { useNavigate } from "react-router";
import { v4 as uuid } from "uuid";
import { Plan } from "./components/plan";
import { getUserSubscriptionThunk } from "src/redux/slices/userSlice";
import { DiscountProvider } from "../paywall/DiscountContext";
import { useUpsalePlans } from "./hooks/useUpsalePlans";

export const Upsale = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [idempotencyKey] = useState(() => uuid());

  const filteredPlans = useUpsalePlans();
  const { activePlan } = useSelector((state) => state.plans);

  useEffect(() => {
    dispatch(getUserSubscriptionThunk());
  }, [dispatch]);

  const handleSkip = () => {
    navigate("/activate");
  };

  return (
    <DiscountProvider>
      <Header onSkip={handleSkip} />
      <div className={styles.layout}>
        <Stepper />
        <Title />
        <CountdownTimer />
        <Plan
          idempotencyKey={idempotencyKey}
          onSkip={handleSkip}
          plans={filteredPlans}
        />
        <p className={styles.note}>{getAgreementTextUpsale(activePlan)}</p>
      </div>
    </DiscountProvider>
  );
};
