import finger from "@assets/img/emoji/fingerup.png";
import { BreakdownWithEmoji } from "../../../../shared";

const data = {
  LOSE_WEIGHT: "slim down at your own pace and pleasure",
  MAINTAIN_WEIGHT: "tone up and look fit"
};

export const WeKnow = ({ onNextStep, onTrackEvent }) => {
  const value = localStorage.getItem("mainGoal");
  const content = data[value];
  const title = "We know how to make it happen!";
  const paragraph = [
    "No more exhausting, lengthy workouts or annoying diet restrictions!",
    `Somatic workouts plan will help you <strong>${content}</strong> in just 15-min a day.`
  ];
  return (
    <BreakdownWithEmoji
      emoji={finger}
      title={title}
      paragraph={paragraph}
      onContinue={() => {
        onNextStep();
        onTrackEvent();
      }}
    />
  );
};
