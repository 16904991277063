import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { CustomCheckoutModal } from "../customCheckoutModal";
import { PaymentButton } from "../../paymentButton";
import { usePaymentButtonMethod } from "../../hooks/usePaymentMethod";
import { Card } from "../card";
import { useElements } from "@stripe/react-stripe-js";
import { Loader } from "@common/loader";
import useIsAndroid from "@hooks/useIsAndroid";
import { Button } from "@common/button";

export const PaymentOptions = ({ activePlan, onCloseCheckout, id }) => {
  const elements = useElements();

  const isAndroid = useIsAndroid();

  // console.log(elements);
  const [isModalOpen, setModalOpen] = useState(false);
  const { isExpressCheckoutAvailable, idPrefix, isLoading } =
    usePaymentButtonMethod();

  useEffect(() => {
    document.body.style.overflow = isModalOpen ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  const openModal = () => setModalOpen(true);

  const closeModal = () => {
    setModalOpen(false);
    onCloseCheckout?.();
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isAndroid) {
    return (
      <>
        <Button className={styles.androidPlanButton} onClick={openModal}>
          GET MY PLAN
        </Button>
        {isModalOpen && (
          <CustomCheckoutModal onClose={closeModal} activePlan={activePlan} />
        )}
      </>
    );
  }

  return (
    <div className={styles.paymentOptionsWrapper}>
      {!isModalOpen && (
        <>
          {isExpressCheckoutAvailable ? (
            <PaymentButton id={`paywall_continue_${idPrefix}${id}`} />
          ) : (
            <Card id={`paywall_continue_${id}`} />
          )}
        </>
      )}
      <button
        onClick={openModal}
        className={`${
          styles.otherPaymentButton
        } ${`paywall_with_other_option_${id}`}`}
      >
        CONTINUE WITH OTHER PAYMENT OPTIONS
      </button>
      {isModalOpen && (
        <CustomCheckoutModal onClose={closeModal} activePlan={activePlan} />
      )}
    </div>
  );
};
