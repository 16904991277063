import { svgPathProperties } from "svg-path-properties";

export const getYatX = (targetX, pathD) => {
  const properties = new svgPathProperties(pathD);

  let length = properties.getTotalLength();
  let precision = 1;

  let minX = Infinity;
  let maxX = -Infinity;

  for (let i = 0; i <= length; i += 1) {
    let point = properties.getPointAtLength(i);
    minX = Math.min(minX, point.x);
    maxX = Math.max(maxX, point.x);
  }

  let realX = minX + (targetX / 100) * (maxX - minX);

  if (realX < minX || realX > maxX) {
    return null;
  }

  let start = 0,
    end = length;
  while (Math.abs(start - end) > precision) {
    let mid = (start + end) / 2;
    let point = properties.getPointAtLength(mid);

    if (Math.abs(point.x - realX) < precision) {
      return {y: point.y, x: realX};
    } else if (point.x < realX) {
      start = mid + precision;
    } else {
      end = mid - precision;
    }
  }
  return null;
};
