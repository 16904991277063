import styles from "./styles.module.scss";
import losted from "@assets/img/emoji/losted.png";
import thinking from "@assets/img/emoji/thinking.png";
import success from "@assets/img/emoji/success.png";

import { IconCard } from "../../../../shared";

const data = [
  {
    value: "1",
    displayValue: "Yes, I rarely have time for myself",
    emoji: losted
  },
  {
    value: "2",
    displayValue: "I struggle but manage to find some time",
    emoji: thinking
  },
  {
    value: "3",
    displayValue: "No, I prioritize self-care and relaxation",
    emoji: success
  }
];
export const SelfCareTroubles = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (v) => {
    onNextStep(v.value);
    onTrackEvent?.(v.displayValue);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Do you have trouble making time for self-care and relaxation?</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCard
              onClick={() => handleNextStep(value)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
