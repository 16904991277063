import styles from "./styles.module.scss";
import dinner from "./img/dinner.jpg";

const DATA = {
  VERY_EARLY: "Between 4 and 6 pm",
  EARLY: "Between 6 and 8 pm",
  LATE: "Between 8 and 10 pm",
  NO_DINNER: "I usually skip dinner"
};

export const Dinner = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (key, value) => {
    onNextStep(key);
    onTrackEvent?.(value);
  };

  return (
    <div className={styles.container}>
      <h2>What time do you have dinner?</h2>
      <img src={dinner} alt="breakfast" className={styles.image} />
      <div className={styles.cards}>
        {Object.entries(DATA).map(([key, value]) => (
          <div
            onClick={() => handleNextStep(key, value)}
            className={styles.card}
            key={key}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};
