import { SOMATIC_KEYS } from "../../../../shared";
import { OptionsWithBackground } from "../../../../shared";
import background from "./bg.png";

const data = [
  { displayValue: "Almost always", value: "1" },
  { displayValue: "Quite often", value: "2" },
  { displayValue: "Rarely", value: "3" },
  { displayValue: "Almost never", value: "4" }
];

export const FeelingStressed = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (v) => {
    console.log(v);
    onNextStep?.();
    onTrackEvent?.(v.displayValue);
    localStorage.setItem(SOMATIC_KEYS.feelingStressed, v.value);
  };
  return (
    <OptionsWithBackground
      data={data}
      title="How often do you find yourself feeling stressed?"
      onClick={handleNextStep}
      background={background}
    />
  );
};
