import { DATA } from "src/routes/onboarding/default/stepper/questions/futureEvent";

function getXfromDate(endDate, midDate) {
  if (!midDate) return 0;
  const start = new Date();
  const end = new Date(endDate);
  const mid = new Date(midDate);

  if (mid < start) return 0;
  if (mid > end) return 100;

  return 100 * ((mid - start) / (end - start));
}

function isSameYearWithToday(date) {
  return new Date(date).getFullYear() === new Date().getFullYear();
}

function getMonthsDifference(endDate) {
  const end = new Date(endDate);
  const now = new Date();

  const yearsDiff = end.getFullYear() - now.getFullYear();
  const monthsDiff = end.getMonth() - now.getMonth();

  return yearsDiff * 12 + monthsDiff;
}

function validateEventDate(eventDate, predictedDate) {
  const event = new Date(eventDate);
  const predicted = new Date(predictedDate);

  return event >= predicted ? null : eventDate;
}

export const getPredictionData = (user) => {
  const ms = localStorage.getItem("ms");

  const weight =
    ms === "us" ? user?.questions?.qqWeightLbs : user?.questions?.qqWeightKg;
  const goal =
    ms === "us"
      ? user?.questions?.qqGoalWeightLbs
      : user?.questions?.qqGoalWeightKg;

  const eventWeight =
    ms === "us"
      ? user?.fitnessProfile?.weightLbsEstimateByOccasionDate
      : user?.fitnessProfile?.weightKgEstimateByOccasionDate;

  const predictedDateFromResponse =
    user?.fitnessProfile?.weightGoalCompletionEstimatedDate;
  const eventResponse = user?.questions.qqOccasion;
  const event = DATA.find((item) => item.value === eventResponse);
  const eventDateFromResponse = user?.questions?.qqOccasionDate;

  const predictedDate = predictedDateFromResponse;
  const eventDate = validateEventDate(
    eventDateFromResponse,
    predictedDateFromResponse
  );

  const monthlyEstimateWeightChangeKoef =
    ms === "us"
      ? user?.fitnessProfile?.monthlyEstimatedWeightChangeLbs
      : user?.fitnessProfile?.monthlyEstimatedWeightChangeKg;

  const minimunWeight = ms === "us" ? 2.2 : 1;

  const monthlyEstimateWeightChange =
    getMonthsDifference(eventDate) * monthlyEstimateWeightChangeKoef ||
    minimunWeight;

  const isLoseWeight = weight > goal && weight !== goal;
  const isGainWeight = weight < goal && weight !== goal;

  const averageWeight = Math.round((weight + goal) / 2);
  const gainWeightMiddle = Math.round(weight + (averageWeight - weight) * 1.5);
  const gainWeightEnd = weight + (averageWeight - weight) * 3;

  const weightChange =
    ms === "us"
      ? user?.fitnessProfile?.monthlyEstimatedWeightChangeLbs
      : user?.fitnessProfile?.monthlyEstimatedWeightChangeKg;

  const axisY = isLoseWeight
    ? [weight, averageWeight, goal]
    : [gainWeightEnd, gainWeightMiddle, weight];

  const eventDatePosition = getXfromDate(predictedDate, eventDate);

  const eventDateXAxisOffset = eventDate
    ? Math.min(70, Math.max(17, eventDatePosition))
    : null;
  const showYearInPredictedDate = !isSameYearWithToday(predictedDate);

  return {
    weight,
    goal,
    eventWeight,
    predictedDate,
    event,
    eventResponse,
    eventDate,
    isLoseWeight,
    isGainWeight,
    weightChange,
    axisY,
    eventDatePosition,
    showYearInPredictedDate,
    eventDateXAxisOffset,
    monthlyEstimateWeightChange,
  };
};