import React, { useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Steps } from "./data/steps";
// import { updateAnswer } from "../../../redux/slices/answersSlice";
import styles from "./styles.module.scss";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./fade.css";
import { UserService } from "@api/services/userService";
import { errorToast } from "@utils/toaster";
import { trackUserEvent } from "@utils/updateDataLayer";

export const CurrentStep = () => {
  const { stepIndex } = useParams();
  const navigate = useNavigate();
  const nodeRef = useRef();
  const [isProcessing, setIsProcessing] = useState(false);

  let currentStep;
  if (isNaN(stepIndex)) {
    currentStep = Steps.find((step) => step.uri === stepIndex);
  } else {
    const index = parseInt(stepIndex, 10) - 1;
    currentStep = Steps[index];
  }

  const Component = currentStep.component;

  const handleNextStep = async (answer) => {
    if (isProcessing) return;

    try {
      setIsProcessing(true);
      const mappedKey = currentStep?.mappingKey;

      if (answer !== undefined && mappedKey) {
        await UserService.updateQuestions({ [mappedKey]: answer });
      }

      if (answer === null && mappedKey === "qqOccasion") {
        await UserService.updateQuestions({ qqOccasionDate: null });
        navigate("/creating-plan");
        return;
      }

      const currentStepIndex = Steps.indexOf(currentStep);

      if (currentStepIndex + 1 >= Steps.length) {
        navigate("/creating-plan");
        return;
      }

      const nextStep = Steps[currentStepIndex + 1];
      const nextStepUri = nextStep.uri || (currentStepIndex + 2).toString();

      navigate(`/onboarding/${nextStepUri}`);
    } catch (error) {
      errorToast({ message: error.message });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleTrackEvent = (answer) => {
    trackUserEvent(answer, currentStep);
  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={stepIndex}
        in={true}
        nodeRef={nodeRef}
        timeout={700}
        classNames="fade-in"
      >
        <div ref={nodeRef} className={styles.step}>
          <Component
            onNextStep={handleNextStep}
            onTrackEvent={handleTrackEvent}
          />
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};
