import { useEffect } from "react";
import styles from "./styles.module.scss";
import { PlanItem } from "./components/planItem";
import { getAgreementText, getPlanPricing } from "./utils/getPlanDetails";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { useDiscount } from "../../DiscountContext";
import { PaymentOptions } from "./components/paymentOptions";
import { StripeElementsProvider } from "./stripeElementsContext";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@common/spinner";
import { Metrics } from "./components/metrics";
import { setActivePlan } from "src/redux/slices/plans";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY
);

export const Plan = ({
  title,
  name,
  isMetrics = false,
  id,
  onCloseCheckout,
  additionalDiscount = false,
}) => {
  const dispatch = useDispatch();
  const {
    somaticPlans,
    easyfitPlans,
    somaticRetentionPlans,
    easyfitRetentionPlans,
    isLoading,
    activePlan,
  } = useSelector((state) => state.plans);

  const isSomatic = process.env.REACT_APP_PRODUCT === "SOMATIC";

  const displayedPlans = isSomatic
    ? additionalDiscount
      ? somaticRetentionPlans
      : somaticPlans
    : additionalDiscount
    ? easyfitRetentionPlans
    : easyfitPlans;

  const sortPlans = (plans) => {
    return [...plans].sort((a, b) => {
      const aHasTrial = a.trialPeriodDuration !== 0;
      const bHasTrial = b.trialPeriodDuration !== 0;

      if (aHasTrial && !bHasTrial) return -1;
      if (!aHasTrial && bHasTrial) return 1;

      return 0;
    });
  };

  const sortedPlans = sortPlans(displayedPlans);

  const { isDiscounted } = useDiscount();

  const agreementText = getAgreementText(activePlan, isDiscounted);

  const options = {
    mode: "subscription",
    currency: activePlan?.currency?.toLowerCase() || "usd",
    amount: activePlan?.priceAmount || 100,
    appearance: {
      variables: {
        borderRadius: "9999px",
        width: "100%",
      },
    },
  };

  const handleClickPlan = (plan) => {
    dispatch(setActivePlan((plan)));
    localStorage.setItem("isTrial", plan.hasTrial.toString());
  };

  return !isLoading ? (
    <StripeElementsProvider>
      <Elements stripe={stripePromise} options={options}>
        <div className={styles.content}>
          <div className={styles.choosePlan}>
            <h2>
              {" "}
              {name && <span>{name}, </span>} {title}
            </h2>
            {isMetrics && <Metrics />}
            <div className={styles.plans}>
              {sortedPlans.map((plan) => {
                const planProps = getPlanPricing(plan);

                return (
                  <PlanItem
                    {...planProps}
                    isDiscounted={isDiscounted}
                    key={plan.id}
                    onClick={() => handleClickPlan(plan)}
                    checked={plan.id === activePlan?.id}
                  />
                );
              })}
            </div>
            <div className={styles.paymentContainer}></div>
            <PaymentOptions
              id={id}
              activePlan={activePlan}
              onCloseCheckout={onCloseCheckout}
            />
            <div className={styles.agreementContainer}>
              <p className={styles.agreement}>{agreementText}</p>
            </div>
          </div>
        </div>
      </Elements>
    </StripeElementsProvider>
  ) : (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  );
};
