import { useState, useEffect } from "react";
import { ArrowButton, Button, Input } from "@common/index";
import { useMeasureSystem } from "@hooks/useMeasureSystem";
import styles from "./styles.module.scss";
import { UserService } from "@api/services/userService";
import { Tooltip } from "src/routes/onboarding/shared/components/tooltip";
import fingerup from "@assets/img/emoji/fingerup.png";
import { MeasurementsSwitch } from "src/routes/onboarding/shared/components/measurementsSwitch";

const title = "Calculating your body mass index";
const tooltipContent =
  "BMI is widely used as a risk factor for the development of or the prevalence of several health issues.";

export const Height = ({ onNextStep, onTrackEvent }) => {
  const { ms, changeMs } = useMeasureSystem();
  const [heightEu, setHeightEu] = useState();
  const [heightUS, setHeightUS] = useState({ ft: undefined, inch: undefined });
  const [isValid, setIsValid] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setIsValid(false);
    setHeightEu(undefined);
    setHeightUS({ ft: undefined, inch: undefined });
  }, [ms]);

  const handleChangeCm = (e) => {
    const cm = e.currentTarget.value;

    if (cm === "") {
      setHeightEu(undefined);
      setIsValid(false);
      return;
    }

    if (cm.length <= 3 && !isNaN(cm)) {
      const numericValue = Number(cm);
      setHeightEu(numericValue);
      setIsValid(numericValue <= 241 && numericValue >= 90);
    }
  };

  const handleChangeFt = (e) => {
    const ft = e.currentTarget.value;

    if (ft === "") {
      setHeightUS((prevState) => ({
        ...prevState,
        ft: undefined
      }));
      setIsValid(false);
      return;
    }

    if (!isNaN(ft)) {
      const numericValue = Number(ft);
      setHeightUS((prevState) => ({
        ...prevState,
        ft: numericValue
      }));
      setIsValid(numericValue >= 3 && numericValue <= 7);
    }
  };

  const handleChangeInch = (e) => {
    const inch = e.currentTarget.value;

    if (inch === "") {
      setHeightUS((prevState) => ({
        ...prevState,
        inch: undefined
      }));
      setIsValid(false);
      return;
    }

    if (!isNaN(inch)) {
      const numericValue = Number(inch);

      if (numericValue <= 11) {
        setHeightUS((prevState) => ({
          ...prevState,
          inch: numericValue
        }));
        setIsValid(
          heightUS.ft !== undefined &&
            heightUS.ft >= 3 &&
            heightUS.ft <= 7 &&
            numericValue >= 0 &&
            numericValue < 12
        );
      }
    }
  };

  const handleNextStep = async () => {
    const payload =
      ms === "us"
        ? { qqHeightFt: heightUS.ft, qqHeightIn: heightUS.inch || 0 }
        : { qqHeightCm: heightEu };

    const heightValue =
      ms === "us" ? `${heightUS.ft}'${heightUS.inch || 0}"` : `${heightEu} cm`;

    try {
      await UserService.updateQuestions(payload);
      onNextStep();
      onTrackEvent(heightValue);
    } catch (e) {
      console.log(e);
    }
  };

  const handleFocus = () => {
    setShowTooltip(true);
  };

  const handleBlur = () => {
    setShowTooltip(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>How tall are you?</h2>
        <MeasurementsSwitch ms={ms} changeMs={changeMs} units={["ft", "cm"]} />
        <div className={styles.inputWrapper}>
          <div className={styles.inputContainer}>
            {ms === "eu" ? (
              <Input
                inputmode="numeric"
                type="number"
                step="1"
                placeholder={"0"}
                value={heightEu}
                onChange={handleChangeCm}
                endAdornment={<h5>cm</h5>}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            ) : (
              <div className={styles.usInputs}>
                <Input
                  inputmode="numeric"
                  onChange={handleChangeFt}
                  type="number"
                  step="1"
                  value={heightUS.ft}
                  placeholder={"0"}
                  endAdornment={<h5>ft</h5>}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <Input
                  inputmode="numeric"
                  value={heightUS.inch}
                  maxLength={3}
                  onChange={handleChangeInch}
                  type="number"
                  step="1"
                  placeholder={"0"}
                  endAdornment={<h5>in</h5>}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </div>
            )}
            <ArrowButton isValid={isValid} handleNextStep={handleNextStep} />
          </div>
          <p className={styles.hint}>
            Please, enter a value from{" "}
            <span>{ms === "eu" ? `90 cm to 241 cm` : `3ft to 7ft 11 in`}</span>
          </p>
        </div>
        {showTooltip && (
          <Tooltip
            icon={fingerup}
            title={title}
            content={tooltipContent}
            background="info"
          />
        )}
      </div>
      <div className={styles.action}>
        <Button onClick={handleNextStep} disabled={!isValid}>
          Continue
        </Button>
      </div>
    </div>
  );
};
