import { DATA } from "./data";
import styles from "./styles.module.scss";
import background from "./img/bg.jpg";
import { StepWithBackgroundWrapper } from "src/routes/onboarding/shared";

export const PeakShape = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (value) => {
    onNextStep(value);
    onTrackEvent?.(value);
  };
  return (
    <StepWithBackgroundWrapper
      title="When were you in your peak physical shape?"
      background={background}
    >
      <div className={styles.cards}>
        {DATA.map((item) => (
          <div
            onClick={() => handleNextStep(item)}
            key={item}
            className={styles.card}
          >
            {item}
          </div>
        ))}
      </div>
    </StepWithBackgroundWrapper>
  );
};
