import styles from "./style.module.scss";
import balance from "./slider/img/balance.png";
import tightness from "./slider/img/tightness.png";
import stubborn from "./slider/img/stubborn.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderData = [
  {
    image: stubborn,
    title: "Lose stubborn fat",
    text: " Stress, worry and emotional burdens can sabotage weight loss efforts. This plan provides you with a well-rounded approach to relieve stored emotions and finally get your dream body.",
  },
  {
    image: balance,
    title: "Restore emotional balance",
    text: "Try out our transformative workout exercises to find inner peace, and let go of overwhelming stress, worry, and emotional weight.",
  },
  {
    image: tightness,
    title: "Reduce muscle tightness",
    text: "Use simple poses and stretches to alleviate tension in your muscles, including the hips, back, neck, shoulders, and more.",
  },
];

export const WorkoutsSlider = () => {
  return (
    <div className={styles.workouts}>
      <h2>Workouts that really work</h2>
      <div className={styles.cards}>
        {sliderData.map((slide, index) => (
          <div
            className={styles.card}
            key={index}
          >
            <div key={index} className={styles.cardImage}>
              <img src={slide.image} alt="Before and After" />
              <div className={styles.titleBlock}>{slide.title}</div>
            </div>
            <div className={styles.description}>
              <p>{slide.text}</p>
            </div>
          </div>
        ))}
      </div>

      <p className={styles.disclaimer}>
        *Disclaimer: In order to achieve the best possible results, it's crucial
        to consistently exercise and follow a balanced diet. We encourage you to
        consult with a healthcare professional before starting any exercise
        program.
      </p>
    </div>
  );
};
