import styles from "./styles.module.scss";
import lunch from "./img/lunch.jpg";

const DATA = {
  VERY_EARLY: "Between 10 am and noon",
  EARLY: "Between noon and 2 pm",
  LATE: "Between 2 and 4 am",
  NO_LUNCH: "I usually skip lunch"
};

export const Lunch = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (key, value) => {
    onNextStep(key);
    onTrackEvent?.(value);
  };

  return (
    <div className={styles.container}>
      <h2>How about lunch?</h2>
      <img src={lunch} alt="lunch" className={styles.image} />
      <div className={styles.cards}>
        {Object.entries(DATA).map(([key, value]) => (
          <div
            onClick={() => handleNextStep(key, value)}
            className={styles.card}
            key={key}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};
