import styles from "./styles.module.scss";

import { IconCard } from "../../../../shared";

import { useState } from "react";
import { Button } from "../../../../../../common";

import img from "./img.png";
import chest from "./Chest.png";
import arms from "./Arms.png";
import belly from "./Belly.png";
import back from "./Back.png";
import legs from "./Legs.png";

import { closeModal, openModal } from "@utils/modal";

const data = [
  {
    img: chest,
    value: "Chest",
    displayValue: "Chest"
  },
  {
    img: arms,
    value: "Arms",
    displayValue: "Arms"
  },
  {
    img: belly,
    value: "Belly",
    displayValue: "Belly"
  },
  {
    img: back,
    value: "Back",
    displayValue: "Back"
  },
  {
    img: legs,
    value: "Legs",
    displayValue: "Legs"
  }
];
export const ChooseTargetZones = ({ onNextStep, onTrackEvent }) => {
  const [checked, setChecked] = useState([]);

  const handleCheck = (value) => {
    setChecked((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((item) => item !== value);
      }
      return [...prevState, value];
    });
  };

  const handleNextStep = () => {
    const dataToSend = checked.length === data.length ? "Full body" : checked;
    console.log(dataToSend);
    closeModal();
    onNextStep(dataToSend);
    onTrackEvent(dataToSend);
  };

  const handleCheckAll = () => {
    if (checked.length === data.length) {
      setChecked([]);
      return;
    }
    setChecked(data.map((i) => i.value));
  };

  const handleOpenModal = () => {
    openModal(
      <div className={styles.modal}>
        <h5>Plan adjusted!</h5>
        <p>
          You'll still get a full-body workout, but we'll focus more on the
          areas you've chosen.
        </p>
        <Button onClick={handleNextStep}>GOT IT</Button>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Choose your target zones</h2>
        <div className={styles.cardsWithImage}>
          <div className={styles.targetZonesContainer}>
            <img src={img} alt="img" />
            {data.map(
              ({ img, value }) =>
                checked.includes(value) && (
                  <img
                    className={styles.targetZoneImage}
                    src={img}
                    alt={value}
                    key={value}
                  />
                )
            )}
          </div>
          <div className={styles.cards}>
            {data.map((value) => (
              <IconCard
                active={checked.includes(value.value)}
                onClick={() => handleCheck(value.value)}
                title={value.displayValue}
                key={value.value}
              />
            ))}
            <IconCard
              active={checked.length === data.length}
              onClick={handleCheckAll}
              title={"Full body"}
            />
          </div>
        </div>
      </div>
      <div className={styles.action}>
        <Button disabled={!checked.length} onClick={handleOpenModal}>
          Continue
        </Button>
      </div>
    </div>
  );
};
