import { axiosInstance } from "@api/index";
import { useState } from "react";

export const useName = (onNextStep) => {
  const [name, setName] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validateName = (value) => {
    const nameRegex = /^[a-zA-Z]+( [a-zA-Z]+){0,2}$/;

    if (
      value.length < 1 ||
      value.length > 20 ||
      !nameRegex.test(value.trim())
    ) {
      return false;
    }

    return true;
  };

  const handleChange = (value) => {
    if (value === "") {
      setName("");
      setIsValid(false);
      return;
    }

    setName(value);
    setIsValid(validateName(value));
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await axiosInstance.post("/me/change-name", { newName: name });
    setIsLoading(false);
    localStorage.setItem("name", name);
    onNextStep();
  };

  return {
    name,
    isValid,
    isLoading,
    handleChange,
    onSubmit,
  };
};
