import { useState } from "react";
import styles from "./styles.module.scss";
import angry from "@assets/img/emoji/angry.png";
import upset from "@assets/img/emoji/upset.png";
import scared from "@assets/img/emoji/scared.png";
import hot from "@assets/img/emoji/hot.png";

import { SOMATIC_KEYS } from "../../../../shared";

import { IconCardCheckbox } from "../../../../shared";
import { Button } from "../../../../../../common";

const data = [
  {
    value: "release stored emotions",
    displayValue: "Release stored emotions",
    emoji: angry
  },
  {
    value: "reduce anxiety",
    displayValue: "Reduce anxiety",
    emoji: upset
  },
  {
    value: "relive stress",
    displayValue: "Relive stress",
    emoji: scared
  },
  {
    value: "get out of survival mode",
    displayValue: "Get out of survival mode",
    emoji: hot
  }
];
export const HopeToAchieve = ({ onNextStep, onTrackEvent }) => {
  const [checked, setChecked] = useState(() => {
    const saved = localStorage.getItem(SOMATIC_KEYS.hopeToAchieve);
    return saved ? JSON.parse(saved) : [];
  });

  const handleCheck = (value) => {
    setChecked((prevState) => {
      const newState = prevState.includes(value)
        ? prevState.filter((item) => item !== value)
        : [...prevState, value];

      localStorage.setItem(
        SOMATIC_KEYS.hopeToAchieve,
        JSON.stringify(newState)
      );
      return newState;
    });
  };

  const handleNextStep = () => {
    onNextStep(checked);
    onTrackEvent(checked);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>What do you hope to achieve?</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCardCheckbox
              checked={checked.includes(value.value)}
              onClick={() => handleCheck(value.value)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
      <div className={styles.action}>
        <Button disabled={!checked.length} onClick={handleNextStep}>
          Continue
        </Button>
      </div>
    </div>
  );
};
