import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AuthPage } from "./routes/auth";
import ProtectedRoutes from "./api/ProtectedRoutes";
import DayExercises from "./routes/dayExercises";
import SomethingWentWrong from "./routes/somethingWentWrong/SomethingWentWrong";
import { useEffect } from "react";
import { LandingPage } from "./routes/landing";
import { PrivacyPolicy, SubscriptionPolicy, TermsOfUse } from "./routes/terms";

import { DATA } from "./routes/onboarding/default/stepper/questions/start/data";
import { usePreloadImages } from "./hooks/usePreloadImages";
import { Workouts } from "./routes/profile/workouts";
import { Workout } from "./routes/profile/workout";
import { Portal } from "./routes/auth/Portal";

import { Profile } from "./routes/profile/activeWorkout";
import { Somatic } from "./routes/onboarding/somatic";
import { EasyfitQuiz } from "./routes/onboarding/defaultNew";
import { Easyfit } from "./routes/onboarding/default";

const APPLICATIONS_MAP = {
  SOMATIC: Somatic,
  EASYFIT_QUIZ: EasyfitQuiz,
  EASYFIT: Easyfit
};

const VERSION = {
  SOMATIC: "1.6",
  EASYFIT: "1.7",
  EASYFIT_QUIZ: "1.4"
};

function App() {
  usePreloadImages(DATA.map((item) => item.image));

  const removeLoader = () => {
    const loader = document.querySelector("#loader");
    if (loader) {
      loader.style.display = "none";
    }
  };
  useEffect(() => {
    if (document.readyState === "complete") {
      removeLoader();
    } else {
      window.addEventListener("load", removeLoader);
    }
  }, []);

  useEffect(() => {
    console.log(`${productType} web: ${VERSION[productType]}`);
  }, []);

  const productType = process.env.REACT_APP_PRODUCT;

  const CurrentApp = APPLICATIONS_MAP[productType] || APPLICATIONS_MAP.EASYFIT;

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<CurrentApp />} />
          <Route element={<Portal />} path="/portal" />
          <Route element={<AuthPage />} path="/auth" />
          <Route element={<LandingPage />} path="/" />
          <Route element={<TermsOfUse />} path="/terms-of-use" />
          <Route element={<PrivacyPolicy />} path="/privacy-policy" />
          <Route element={<SubscriptionPolicy />} path="/subscription-policy" />
          <Route
            element={<SomethingWentWrong />}
            path="/something-went-wrong"
          />
          <Route element={<ProtectedRoutes />}>
            <Route element={<Profile />} path="/profile" />
            <Route element={<Workouts />} path="/workouts" />
            <Route element={<Workout />} path={`/workouts/:title`} />
            <Route element={<DayExercises />} path="day-exercises/:id" />
            <Route
              element={<SomethingWentWrong />}
              path="/something-went-wrong"
            />
          </Route>
          <Route
            path="/onboarding"
            element={<Navigate to="/onboarding/1" replace />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
