import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Loader } from "../common/loader";
import { useLayoutEffect } from "react";
import { getUserInfoThunk } from "../redux/slices/userSlice";
import useLocationEvent from "@hooks/useLocationEvent";
import { getPlansThunk } from "src/redux/slices/plans";

export const RoutesWithEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.user);

  useLocationEvent()

  useLayoutEffect(() => {
    dispatch(getUserInfoThunk({ checkForEmail: true, navigate }));
    if (location.pathname === "/paywall" || location.pathname === "/upsale") {
      localStorage.removeItem("isTrial");
      dispatch(getPlansThunk());
    }
  }, [dispatch, navigate, location.pathname]);

  return isLoading ? <Loader /> : <Outlet />;
};
