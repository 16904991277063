import { OptionsWithBackground } from "../../../../shared";
import background from "./bg.png";

const data = [
  {
    value: "ADVANCED",
    displayValue: "Pretty flexeible "
  },
  {
    value: "AVERAGE",
    displayValue: "Average"
  },
  {
    value: "LIMITED",
    displayValue: "Not flexible"
  },
  {
    value: "UNKNOWN",
    displayValue: "Not sure"
  }
];

export const HowFlexible = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (v) => {
    console.log(v.value);
    onNextStep?.(v.value);
    onTrackEvent?.(v.displayValue);
  };
  return (
    <OptionsWithBackground
      data={data}
      title="How flexible are you?"
      onClick={handleNextStep}
      background={background}
    />
  );
};
