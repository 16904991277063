import styles from "./styles.module.scss";
import work from "@assets/img/emoji/work.png";
import girl from "@assets/img/emoji/girl.png";
import sneaker from "@assets/img/emoji/sneaker.png";

import { IconCard } from "../../../../shared";

const data = [
  {
    value: "MOSTLY_SITTING",
    displayValue: "I spend most of the day sitting ",
    emoji: work
  },
  {
    value: "SITTING_BUT_ACTIVE_BREAKS",
    displayValue: "I take active breaks",
    emoji: girl
  },
  {
    value: "MOSTLY_ON_FEET",
    displayValue: " I'm on my feet all day long",
    emoji: sneaker
  }
];
export const TypicalDay = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (v) => {
    onNextStep(v.value);
    onTrackEvent?.(v.displayValue);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>How would you describe your typical day?</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCard
              onClick={() => handleNextStep(value)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
