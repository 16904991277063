import styles from "./styles.module.scss";

export const Chart = () => {
  return (
    <svg
      width="343"
      height="233"
      viewBox="0 0 343 233"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3797_36423)">
        <rect width="343" height="241" fill="#F8F8F8" />
        <path d="M12 31H331" stroke="#D9D9D9" strokeDasharray="2 2" />
        <path d="M12 65.2H331" stroke="#D9D9D9" strokeDasharray="2 2" />
        <path d="M12 99.3999H331" stroke="#D9D9D9" strokeDasharray="2 2" />
        <path d="M12 133.6H331" stroke="#D9D9D9" strokeDasharray="2 2" />
        <path d="M12 167.8H331" stroke="#D9D9D9" strokeDasharray="2 2" />
        <path d="M12 202H331" stroke="#D9D9D9" strokeDasharray="2 2" />
        <path
          opacity="0.2"
          d="M20 202V32.05C114.5 134.5 247 170.5 323 167.817V202H20Z"
          fill="#058D66"
        />
        <path
          d="M323 168C234 168 111 128.5 20 32"
          stroke="#058D66"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g filter="url(#filter0_d_3797_36423)">
          <circle cx="291" cy="165" r="5" fill="#7C8AF7" />
          <circle cx="291" cy="165" r="6" stroke="white" strokeWidth="2" />
        </g>
        <g filter="url(#filter1_d_3797_36423)">
          <circle
            cx="54"
            cy="65"
            r="5"
            fill="white"
            shapeRendering="crispEdges"
          />
          <circle
            cx="54"
            cy="65"
            r="6"
            stroke="black"
            strokeOpacity="0.16"
            strokeWidth="2"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter2_d_3797_36423)">
          <circle
            cx="166"
            cy="134"
            r="5"
            fill="white"
            shapeRendering="crispEdges"
          />
          <circle
            cx="166"
            cy="134"
            r="6"
            stroke="black"
            strokeOpacity="0.16"
            strokeWidth="2"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter3_d_3797_36423)">
          <circle
            cx="102"
            cy="100"
            r="5"
            fill="white"
            shapeRendering="crispEdges"
          />
          <circle
            cx="102"
            cy="100"
            r="6"
            stroke="black"
            strokeOpacity="0.16"
            strokeWidth="2"
            shapeRendering="crispEdges"
          />
        </g>
        <rect x="192" y="104" width="115" height="27" rx="7" fill="#7C8AF7" />
        <path
          d="M299 130.9L294.324 137.701C293.927 138.279 293.073 138.279 292.676 137.701L288 130.9H299Z"
          fill="#7C8AF7"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3797_36423"
          x="276"
          y="150"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3797_36423"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3797_36423"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_3797_36423"
          x="39"
          y="50"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3797_36423"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3797_36423"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_3797_36423"
          x="151"
          y="119"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3797_36423"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3797_36423"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_3797_36423"
          x="87"
          y="85"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3797_36423"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3797_36423"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3797_36423">
          <rect width="343" height="233" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
