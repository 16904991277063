import { Button } from "@common/index";
import styles from "./styles.module.scss";
import { useName } from "src/routes/onboarding/shared";
import { useNavigate } from "react-router";
import { trackUserEvent } from "@utils/updateDataLayer";

const title = "What's your name?";


export const Name = () => {
  const navigate = useNavigate();
  const onNextStep = () => {
    trackUserEvent(name, {
      screenId: "Name",
      screenTitle: title,
    });
    navigate("/email");
  };
  const { name, isValid, handleChange, onSubmit, isLoading } =
    useName(onNextStep);

  const handleSubmit = async () => {
    onSubmit();
  };

  return (
    <div className={styles.container} id="breakdown">
      <div className={styles.content}>
        <h2>{title}</h2>
        <div className={styles.input}>
          <input
            placeholder="Name"
            type="text"
            value={name}
            onChange={(e) => handleChange(e.currentTarget.value)}
          />
        </div>
      </div>
      <div className={styles.action}>
        <Button onClick={handleSubmit} disabled={!isValid || isLoading}>
          Continue
        </Button>
      </div>
    </div>
  );
};
