import { OptionsWithBackground } from "../../../../shared";
import background from "./bg.png";

const data = [
  {
    displayValue: "Yes",
    value: "1"
  },
  {
    value: "0",
    displayValue: "No"
  }
];

export const TriedYoga = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (v) => {
    console.log(v);
    onNextStep?.();
    onTrackEvent?.(v.displayValue);
  };
  return (
    <OptionsWithBackground
      data={data}
      title="Have you tried yoga, stretching, or pilates?"
      onClick={handleNextStep}
      background={background}
    />
  );
};
