import styles from "./styles.module.scss";
import { Header } from "./header";
import { Hero } from "./hero";
import { Features } from "./features";
import { Brands } from "./brands";
import { Transform } from "./transform";
import { Reviews } from "./reviews";
import { FAQSection } from "./faq";
import { Footer } from "./footer";
import { Results } from "./results";

export const LandingPage = () => {
  return (
    <div className={styles.container}>
      <Header />
      <Hero />
      <Brands />
      <Transform />
      <Features />
      <Results />
      <Reviews />
      <FAQSection />
      <Footer />
    </div>
  );
};
