import {
  AnimalProductSchick,
  AnimalProductsEgg,
  BarbecueStreakShrimp,
  BreadGlutenFree,
  Duotone,
  EcologyLeaf,
  MilkCarton,
  SeafoodSalmon,
  ToolsKitchenServingDome,
  VegetablesSalad
} from "@assets/Icons";
import { IconCard } from "@common/iconCard";
import styles from "./styles.module.scss";

const DATA = [
  {
    type: "diet_with_meat",
    title: "includes meat",
    options: [
      {
        title: "Traditional",
        paragraph: "I enjoy everything",
        icon: ToolsKitchenServingDome
      },
      {
        title: "Keto",
        paragraph: "I prefer high-fat low-carb meals",
        icon: BarbecueStreakShrimp
      },
      {
        title: "Paleo",
        paragraph: "I don't eat processed foods",
        icon: AnimalProductSchick
      }
    ]
  },
  {
    type: "diet_no_meat",
    title: "excludes meat",
    options: [
      {
        title: "Vegetarian",
        paragraph: "I avoid meat and fish",
        icon: AnimalProductsEgg
      },
      {
        title: "Vegan (Plant Diet)",
        paragraph: "I do not eat animal products",
        icon: EcologyLeaf
      },
      {
        title: "Keto Vegan",
        paragraph: "I eat low-carb plant-based meals",
        icon: Duotone
      }
    ]
  },
  {
    type: "diet_with_fish",
    title: "includes fish",
    options: [
      {
        title: "Mediterranean",
        paragraph: "I eat plenty of veggies, grains and seafood",
        icon: VegetablesSalad
      },
      {
        title: "Pescatarian",
        paragraph: "I avoid meat but enjoy fish",
        icon: SeafoodSalmon
      }
    ]
  },
  {
    type: "diet_no_allerg",
    title: "excludes allergens",
    options: [
      {
        title: "Lactose Free",
        paragraph: "I do not consume foods with lactose",
        icon: MilkCarton
      },
      {
        title: "Gluten Free",
        paragraph: "I avoid gluten-containing grains",
        icon: BreadGlutenFree
      }
    ]
  }
];

export const DietTypes = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (value, type, title) => {
    onNextStep(value);
    onTrackEvent?.(title);
  };

  return (
    <div className={styles.container}>
      <h2>What type of diet do you adhere to?</h2>
      {DATA.map((item) => (
        <div className={styles.group} key={item.type}>
          <p className={styles.groupTitle}>{item.title}</p>
          <div className={styles.cards}>
            {item.options.map(({ title, paragraph, icon }) => (
              <IconCard
                onClick={() => handleNextStep(paragraph, item.type, title)}
                img={icon()}
                title={title}
                paragraph={paragraph}
                key={title}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
