export const CurrencyMap = {
  USD: "$",
  EUR: "€",
  CAD: "C$",
  AUD: "A$",
  GBP: "£",
};

export const getPlanDuration = (duration) => {
  switch (duration) {
    case 1:
      return "1 week";
    case 4:
      return "4 weeks";
    case 12:
      return "12 weeks";
    default:
      return `${duration} weeks`;
  }
};

export const getAgreementText = (plan) => {
  const isTrial = plan?.hasTrial;
  const {
    priceOld,
    duration,
    currency,
    trialPeriodDuration,
  } = getPlanPricing(plan);
  const durationText = getPlanDuration(duration);
  const trialDurationText = getPlanDuration(trialPeriodDuration);

  return isTrial ? (
    <>
      We've automatically applied the discount to your {trialDurationText} trial
      price. Please note that your subscription will be automatically renewed at
      the full price of {CurrencyMap[currency]}
      {priceOld} for every {duration} at the end of your trial and will then be
      continuously renewed until you cancel via your personal account page on
      the website or app.
    </>
  ) : (
    <>
      We've automatically applied the discount to your first {durationText}.
      Please note that your subscription will be automatically renewed at the
      full price of {CurrencyMap[currency]}
      {priceOld} every {durationText}. If you don't want to be billed, you may
      cancel your subscription via your personal account page on the website or
      app.
    </>
  );
};

export const getAgreementTextUpsale = (plan, isDiscounted) => {
  const { priceOld, duration } = getPlanPricing(plan);
  const durationText = getPlanDuration(duration);

  return (
    <>
      PLEASE NOTE: In addition to your subscription, your account will be
      charged ${priceOld} for {durationText} for the selected add-ons. Payment
      will be processed automatically based on the billing information you
      provided earlier. Subscription will renew automatically at the end of each
      period.
    </>
  );
};

export const getPlanPricing = (plan) => {
  return {
    title: plan?.title,
    planHeader: plan?.uiDisplayOptions?.title,
    priceNew: (plan?.priceAmount / 100).toFixed(2),
    priceOld: (plan?.noDiscountPriceAmount / 100).toFixed(2),
    perDay: (plan?.dailyPriceAmount / 100).toFixed(2),
    perDayOld: (plan?.noDiscountDailyPriceAmount / 100).toFixed(2),
    currency: plan?.currency || "USD",
    duration: plan?.billingPeriodDuration,
    billingPeriodDuration: plan?.billingPeriodDuration,
    billingPeriodUnit: plan?.billingPeriodUnit,
    discountPercentage: plan?.discountPercentage?.toFixed(0),
    trialPeriodDuration: plan?.trialPeriodDuration || 0,
    trialPeriodUnit: plan?.trialPeriodUnit,
    trialDailyPriceAmount: (plan?.trialDailyPriceAmount / 100)?.toFixed(2),
    trialDiscountPercentage: plan?.trialDiscountPercentage?.toFixed(0),
    trialNoDiscountDailyPriceAmount: (
      plan?.trialNoDiscountDailyPriceAmount / 100
    )?.toFixed(2),
    trialNoDiscountPriceAmount: (
      plan?.trialNoDiscountPriceAmount / 100
    )?.toFixed(2),
    trialPriceAmount: (plan?.trialPriceAmount / 100)?.toFixed(2),
  };
};
