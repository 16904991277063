import { Button } from "@common/index";
import styles from "./styles.module.scss";

export const PeopleChosen = ({ onNextStep, onTrackEvent }) => {
  return (
    <div className={styles.container} id="breakdown">
      <div>
        <h1>
          150 million <br /> people
        </h1>
        <p>have chosen EasyFit.</p>
      </div>
      <div className={styles.action}>
        <Button
          onClick={() => {
            onNextStep();
            onTrackEvent();
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
