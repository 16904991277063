import { ImageCard } from "@common/index";
import { usePreloadImages } from "@hooks/usePreloadImages";
import { DATA } from "./data";
import styles from "./styles.module.scss";
import weight from "../weightFluctulate/img/bg.jpg";
import slimDown from "../slimDown/img/slimdown.jpg";
import shape from "../peakShape/img/bg.jpg";
import fitnessLevel from "../fitnessLevel/img/bg.jpg";

export const DreamBody = ({ onNextStep, onTrackEvent }) => {
  usePreloadImages([weight, slimDown, shape, fitnessLevel]);

  const handleNextStep = (value) => {
    console.log(value);
    onNextStep(value);
    onTrackEvent(value);
  };
  return (
    <div className={styles.container}>
      <h2>What is your “dream body”?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([title, img]) => (
          <ImageCard
            onClick={() => handleNextStep(title)}
            text={title}
            img={img}
            key={title}
          />
        ))}
      </div>
    </div>
  );
};
