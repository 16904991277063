import { updateDataLayer } from "@utils/updateDataLayer";
import { useEffect } from "react";
import { useLocation } from "react-router";

const LOCATION_EVENT_MAP = {
  Confirm_email_page_load: "/activate",
  Email_validated_page_load: "/thankyou",
  Login_page_load: "/auth",
  Home_page_load: "/profile",
  "1st_workout_started": "/day-exercises/1",
};

const useLocationEvent = () => {
  const location = useLocation();

  useEffect(() => {
    const currentLocationEvent = Object.entries(LOCATION_EVENT_MAP).find(
      ([e, l]) => location.pathname === l
    );

    if (!currentLocationEvent) {
      return;
    }

    const isEventInDataLayer = window.dataLayer.some(
      (event) => event.event === currentLocationEvent[0]
    );

    if (!isEventInDataLayer) {
      updateDataLayer({ event: currentLocationEvent[0] });
    }
  }, [location]);
};

export default useLocationEvent;
