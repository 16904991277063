import { ftToCm, kgToLbs, lbsToKg } from "@utils/measurements";
import { Tooltip } from "src/routes/onboarding/shared/components/tooltip";
import cup from "@assets/img/emoji/cup.png";
import heartgreen from "@assets/img/emoji/heartgreen.png";
import ok from "@assets/img/emoji/ok.png";
import muscle from "@assets/img/emoji/muscle.png";
import exclamation from "@assets/img/emoji/exclamation.png";
import fire from "@assets/img/emoji/fire.png";

const getWeightLossTooltipData = (percent) => {
  const absPercent = Math.abs(percent);

  switch (true) {
    case absPercent <= 4:
      return {
        title: `EASY WIN: lose ${absPercent}% of your weight`,
        content:
          "As per a study by the University of Utah, even 15‑minute workouts every day can help you keep fit and improve your sleep and energy levels.",
        background: "warning",
        icon: cup
      };
    case absPercent <= 9:
      return {
        title: `REALISTIC TARGET: lose ${absPercent}% of your weight`,
        content:
          "As per a study by the University of Utah, even 15‑minute workouts every day can help you keep fit and improve your sleep and energy levels.",
        background: "warning",
        icon: ok
      };
    case absPercent <= 19:
      return {
        title: `HEALTH BENEFITS: lose ${absPercent}% of your weight`,
        content:
          "There’s scientific evidence that some obesity-related conditions improve with 10% or higher weight loss: a reduced chance of having a heart attack, lower blood sugar, and decreased inflammation in blood vessels.",
        background: "normal",
        icon: heartgreen
      };
    default:
      return {
        title: `CHALLENGING GOAL: lose ${absPercent}% of your weight`,
        content:
          "In a new study by Mayo Clinic, overweight people who lose more than 20% of their body weight are almost 2½ times more likely to have good metabolic health as those who lose 5-10%.",
        background: "warning",
        icon: muscle
      };
  }
};
const getWeightGainTooltipData = (weightDiff, gainWeightPercent, unit) => {
  const muscleGain = weightDiff * 0.5;
  const muscleGainPercent = gainWeightPercent / 2;

  if (muscleGain <= 2) {
    return {
      title: `EASY WIN: develop ${muscleGain} ${unit} of muscles`,
      content: `${muscleGain}  of healthy muscle will improve your strength and stability and may boost your confidence to try new activities.`,
      background: "warning",
      icon: cup
    };
  } else if (muscleGain <= 3.4) {
    return {
      title: `REALISTIC TARGET: develop ${muscleGain} ${unit} of muscles`,
      content: `Adding ${muscleGainPercent}% of muscle can increase your resting metabolism. Good metabolism helps you to maintain a healthy weight.`,
      background: "warning",
      icon: ok
    };
  } else if (muscleGain <= 5) {
    return {
      title: `HARD WORK: develop ${muscleGain} ${unit} of muscles`,
      content:
        "Strong muscles lead to strong bones. Train harder to minimize the risk of fractures. Slow down bone loss and regain some lost bone mass.",
      background: "warning",
      icon: muscle
    };
  } else {
    return {
      title: `CHALLENGING GOAL: develop ${muscleGain} ${unit} of muscles`,
      content:
        "Research shows that strength exercises can enhance metabolic function. You will see faster gains with a diet that provides plenty of protein.",
      background: "warning",
      icon: fire
    };
  }
};

export const GoalHint = ({ weight, height, weightGoal }) => {
  const ms = localStorage.getItem("ms");
  const unit = ms === "us" ? "lbs" : "kg";
  const heightMS = ms === "us" ? ftToCm(height) : height;
  const isGainWeight = weightGoal > weight;
  const minimumWeight =
    heightMS * heightMS * 0.0004246 + 0.2712 * heightMS - 14.9057;
  const gainWeightPercent = Math.round((weightGoal / weight) * 100) - 100;
  const weightDiff = weightGoal - weight;

  const weightGoalMs = ms === "us" ? lbsToKg(weightGoal) : weightGoal;

  const isCriticalWeight = weightGoalMs < Math.round(minimumWeight);

  const tooltipData = isGainWeight
    ? getWeightGainTooltipData(weightDiff, gainWeightPercent, unit)
    : getWeightLossTooltipData(gainWeightPercent);

  const roundedMinWeight =
    ms === "us"
      ? `${Math.round(kgToLbs(minimumWeight))} lbs`
      : `${Math.round(minimumWeight)} kg`;

  return isCriticalWeight ? (
    <Tooltip
      icon={exclamation}
      title={"Uh-oh! Low weight alert!"}
      content={`Any weight below ${roundedMinWeight} for your height is classified as underweight and is not recommended by World Health Organization.`}
      background={"attention"}
    />
  ) : (
    <Tooltip
      icon={tooltipData.icon}
      title={tooltipData.title}
      content={tooltipData.content}
      background={tooltipData.background}
    />
  );
};
