import { SOMATIC_KEYS } from "src/routes/onboarding/shared";
import styles from "./styles.module.scss";
import { Button } from "@common/button";
import { useNavigate } from "react-router";
import { trackUserEvent } from "src/utils/updateDataLayer";

export const Newsletter = () => {
  const navigate = useNavigate();
  const handleNextStep = (value) => {
    const selectedOption = value
      ? "SURE, I'M IN!"
      : "I DON'T WANT TO RECEIVE TIPS OR UPDATES";

    localStorage.setItem(SOMATIC_KEYS.newsletter, JSON.stringify(value));
    navigate("/name");
    trackUserEvent?.(selectedOption, {
      screenId: "Email_consent",
      screenTitle:
        "Do you want to receive emails with Weight Loss tips and our product updates?"
    });
  };

  return (
    <div className={styles.container} id="breakdown">
      <div className={styles.content}>
        <h2>
          Do you want to receive emails with <span>Weight Loss</span> tips and
          our product updates?
        </h2>
      </div>
      <Button onClick={() => handleNextStep(true)}>SURE, I'M IN!</Button>
      <button className={styles.decline} onClick={() => handleNextStep(false)}>
        <p>I DON'T WANT TO RECEIVE TIPS OR UPDATES</p>
      </button>
    </div>
  );
};
