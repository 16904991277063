import { useState } from "react";
import styles from "./styles.module.scss";
import { getPlanDuration, getPlanPricing } from "../../utils/getPlanDetails";
import { Card } from "../card";
import { PaymentButton } from "../../paymentButton";
import PaddleCheckout from "../../paypal";
import paypal from "@assets/img/png/PayPal.png";
import Visa from "@assets/img/png/Visa.png";
import MasterCard from "@assets/img/png/MasterCard.png";
import Maestro from "@assets/img/png/Maestro.png";

import { usePaymentButtonMethod } from "../../hooks/usePaymentMethod";
import { formatPeriodUnit } from "@utils/formatUnit";
import { CardLogos } from "../cardLogos";

export const CustomCheckoutModal = ({ onClose, activePlan }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("credit-card");

    console.log(activePlan)

  const isTrial = localStorage.getItem("isTrial") === "true";

  const planDetails = getPlanPricing(activePlan);
  const { idPrefix } = usePaymentButtonMethod();

  const currency = planDetails.currency;
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>

        <h3>Select your payment method</h3>

        <div className={styles.switch}>
          <div
            onClick={() => setSelectedPaymentMethod("paypal")}
            className={`${styles.switchItem} ${
              selectedPaymentMethod === "paypal" ? styles.active : ""
            }`}
          >
            <div className={styles.paypal}>
              <img src={paypal} alt="paypal" />
            </div>
          </div>
          <div
            onClick={() => setSelectedPaymentMethod("credit-card")}
            className={`${styles.switchItem} ${
              selectedPaymentMethod === "credit-card" ? styles.active : ""
            }`}
          >
            <div className={styles.card}>
              <h5>Credit card</h5>
              <div className={styles.cards}>
                <img src={Visa} alt="Visa" />
                <img src={MasterCard} alt="MasterCard" />
                <img src={Maestro} alt="Maestro" />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.price}>
          <div className={styles.regularPrice}>
            <span>
              Regular{" "}
              {formatPeriodUnit(
                planDetails.billingPeriodDuration,
                planDetails.billingPeriodUnit
              )}{" "}
              price
            </span>
            <span>
              {isTrial ? planDetails.trialPriceAmount : planDetails.priceNew}{" "}
              {currency}
            </span>
          </div>
          <hr />
          <div className={styles.totalPrice}>
            <span>Total</span>
            <span>
              {isTrial ? planDetails.trialPriceAmount : planDetails.priceNew}{" "}
              {currency}
            </span>
          </div>
        </div>

        <div className={styles.payment}>
          {selectedPaymentMethod === "paypal" ? (
            <div className={styles.paypal}>
              <PaddleCheckout id="paywall_continue_modal_paypal" />
            </div>
          ) : (
            <div>
              <div className={styles.paymentButton}>
                <PaymentButton id={`paywall_continue_modal_${idPrefix}`} />
              </div>
              <div className={styles.cardWrapper}>
                <CardLogos />
                <Card id="paywall_continue_modal_card" isInModal />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
