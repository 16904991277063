import { BreakdownWithImage, SOMATIC_KEYS } from "../../../../shared";
import img from "./img.png";

const texts = {
  awesome: {
    title: "We think you're awesome!",
    paragraph: [
      "You’re ahead of 93% of our users.",
      "Now, try Somatic exercises to <strong>help your body recover and relax</strong> — boosting your progress even further."
    ]
  },
  improving: {
    title: "Great job! You are improving your fitness quickly through exercise",
    paragraph: [
      "Achieve your fitness goals in less time! Let us know your preferences, and we will provide you with the right set of somatic exercises."
    ]
  },
  canStart: {
    title:
      "It's perfectly fine not to exercise. You can start working out for just 15 minutes a day.",
    paragraph: [
      "Reach your weight goal in a shorter amount of time! Just let us know your preferences and we will back you up."
    ]
  }
};

const textsConditions = {
  awesome: (v) => v < 3,
  improving: (v) => v > 2 && v < 5,
  canStart: (v) => v === 5
};

export const YouAwesome = ({ onNextStep, onTrackEvent }) => {
  const oftenWorkout = +localStorage.getItem(SOMATIC_KEYS.oftenWorkout);

  const getTexts = () => {
    const key = Object.entries(textsConditions).find(([, condition]) =>
      condition(oftenWorkout)
    )[0];
    return texts[key];
  };

  const { title, paragraph } = getTexts();

  return (
    <BreakdownWithImage
      title={title}
      paragraph={paragraph}
      img={img}
      onContinue={() => {
        onNextStep();
        onTrackEvent?.();
      }}
    />
  );
};
