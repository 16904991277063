import { BreakdownWithImage } from "../../../../shared";
import img from "./img.png";

export const ReleaseTension = ({ onNextStep, onTrackEvent }) => {
  const paragraph = [
    "When stress becomes a daily part of life, it often leads to <strong>muscle tension, aches, headaches, and disrupted sleep.</strong>",
    "Our somatic exercises <strong>can help you release tension</strong> and take care of your body in the comfort of your own home."
  ];
  return (
    <BreakdownWithImage
      title="Release tension from your shoulders for full body relief"
      paragraph={paragraph}
      img={img}
      onContinue={() => {
        onNextStep();
        onTrackEvent?.();
      }}
    />
  );
};
