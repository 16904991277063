import { ArrowButton, Button, Input } from "@common/index";
import styles from "./styles.module.scss";
import { useState } from "react";
import { Tooltip } from "src/routes/onboarding/shared/components/tooltip";
import fingerup from "@assets/img/emoji/fingerup.png";

export const Age = ({ onNextStep, onTrackEvent }) => {
  const [age, setAge] = useState();
  const [isValid, setIsValid] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleChange = (e) => {
    const value = e.currentTarget.value;

    if (value === "") {
      setAge("");
      setIsValid(false);
      return;
    }

    if (value.length <= 2 && !isNaN(value)) {
      const numericValue = Number(value);
      setAge(numericValue);

      setIsValid(numericValue <= 80 && numericValue >= 18);
    }
  };

  const handleNextStep = () => {
    onNextStep(age);
    onTrackEvent(age);
  };

  const handleFocus = () => {
    setShowTooltip(true);
  };

  const handleBlur = () => {
    setShowTooltip(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>What is your age?</h2>
        <div className={styles.inputWrapper}>
          <div className={styles.inputContainer}>
            <Input
              inputmode="numeric"
              type="number"
              step="1"
              placeholder={"Age"}
              value={age}
              onChange={handleChange}
              endAdornment={<h5>years</h5>}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <ArrowButton isValid={isValid} handleNextStep={handleNextStep} />
          </div>

          <p className={styles.hint}>
            Please, enter a value from <span>18 to 80</span>
          </p>
        </div>
        {showTooltip && (
          <Tooltip
            icon={fingerup}
            title="We ask your age to create your personal plan"
            content="Older people tend to have more body fat than younger people with the same BMI."
            background="info"
          />
        )}
      </div>
      <div className={styles.action}>
        <Button onClick={handleNextStep} disabled={!isValid}>
          Continue
        </Button>
      </div>
    </div>
  );
};
