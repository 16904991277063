import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { ChartGainWeight, ChartLoseWeight } from "../../../prediction/charts";
import { getPredictionData } from "../../../prediction/utils/getPredictionData";
import { formatDate } from "@utils/formatDate";

export const PaywallChart = () => {
  const user = useSelector((state) => state.user.userInfo);
  const ms = localStorage.getItem("ms");

  const {
    goal,
    predictedDate,
    eventDate,
    isLoseWeight,
    axisY,
    showYearInPredictedDate,
    eventDateXAxisOffset,
    eventWeight
  } = getPredictionData(user);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.text}>
          <p className={styles.subtitle}>
            According to the information you have provided us, you'll achieve
            your goal weight of
          </p>
          <p className={styles.predictedData}>
            {Math.ceil(eventWeight || goal)} {ms === "us" ? "lbs" : "kg"} <span>by</span>{" "}
            {formatDate(eventDate || predictedDate)}
          </p>
        </div>

        <div className={styles.chart}>
          <div className={styles.axisx}>
            <span>
              {new Date().toLocaleString("en-US", {
                month: "short",
                day: "2-digit",
              })}
            </span>
            <span>
              {new Date(predictedDate).toLocaleString("en-US", {
                month: "short",
                day: "2-digit",
                year: showYearInPredictedDate ? "numeric" : undefined,
              })}
            </span>
          </div>
          <div className={styles.axisy}>
            {axisY.map((value, index) => {
              return value && <span key={index}>{value}</span>;
            })}
          </div>
          {eventDate && (
            <div
              className={styles.eventDate}
              style={{
                left: `${eventDateXAxisOffset}%`,
                transform: `translateX(-${eventDateXAxisOffset}%)`,
              }}
            >
              <span>
                {new Date(eventDate).toLocaleString("en-US", {
                  month: "short",
                  day: "2-digit",
                })}
              </span>
            </div>
          )}
          <div
            className={
              isLoseWeight
                ? `${styles.label}`
                : `${styles.label} ${styles.gain}`
            }
          >
            Goal <br />
            {goal} {ms === "us" ? "lbs" : "kg"}
          </div>

          {isLoseWeight ? (
            <ChartLoseWeight x={eventDateXAxisOffset} />
          ) : (
            <ChartGainWeight x={eventDateXAxisOffset} />
          )}
        </div>
      </div>
    </div>
  );
};
