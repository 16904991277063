import { Check, Warning } from "@assets/Icons";
import styles from "./styles.module.scss";

const BMIHintsMap = {
  Normal: {
    icon: <Check />,
    paragraph: "Great starting point to get in shape and improve health",
    title: "Healthy BMI"
  },
  Overweight: {
    icon: <Warning />,
    paragraph:
      "Excess weight can increase health problems like high blood pressure or cardiovascular diseases",
    title: "Risk of high BMI"
  },
  Obese: {
    icon: <Warning color="#FF6A6A" />,
    paragraph:
      "Excess weight can increase health problems like high blood pressure or cardiovascular diseases",
    title: "Risk of high BMI"
  },
  Underweight: {
    icon: <Warning color="#56A9F7" />,
    paragraph:
      "Being underweight can lead to weakened immune function and increased risk of other health issues",
    title: "Risk of low BMI"
  }
};

export const BMIHint = ({ bmiType }) => {
  const BMIHintInfo = BMIHintsMap[bmiType] || BMIHintsMap.Underweight;
  return (
    <div className={`${styles.card} ${styles[bmiType]}`}>
      <div className={styles.title}>
        {BMIHintInfo.icon} <h6>{BMIHintInfo.title}:</h6>
      </div>
      <div className={styles.paragraph}>{BMIHintInfo.paragraph}</div>
    </div>
  );
};
