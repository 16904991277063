import {
  PeopleChosen,
  Initiate,
  MainGoal,
  TransformBody,
  HopeToAchieve,
  FeelingStressed,
  FeelingExhausted,
  CryingLastTime,
  UnressolvedEmotions,
  BrainFog,
  EmotionallyAffected,
  LastTimeRelaxed,
  RelaseYourself,
  StruggleSetbacks,
  PeopleGiveUp,
  BodyImage,
  ChooseBodyType,
  CompositionWantToHave,
  HowWeightChange,
  ReadyToLose,
  WithoutStress,
  ChooseTargetZones,
  LoadingChart,
  ChooseFocus,
  SciaticaPain,
  Headaches,
  SufferFromConditions,
  TakingMedications,
  BackProblems,
  SensitiveAreas,
  ThankForSharing,
  TensionInBody,
  TensionFeel,
  ReleaseTension,
  StruggleSleep,
  SleepIsKey,
  OftenWorkout,
  YouAwesome,
  KnowAboutSomatic,
  HowFlexible,
  YouAreAhead,
  BestShape,
  SelfCareTroubles,
  TriedYoga,
  TypicalDay,
  SpareForSelfCare,
  WeKnow
} from "../questions";
import { Age } from "../questions/age";
import { BiggestMotivation } from "../questions/biggestMotivation";
import { EventDate } from "../questions/eventDate";
import { FutureEvent } from "../questions/futureEvent";
import { Height } from "../questions/height";
import { Report } from "../questions/report";
import { Weight } from "../questions/weight";
import { WeightGoal } from "../questions/weightGoal";

export const groupTitles = [
  "Goals",
  "Emotional profile",
  "Body profile",
  "Activity",
  "Almost there",
  "Almost Done"
];

export const Steps = [
  {
    groupIndex: 0,
    component: Initiate,
    screenId: "Start_age_full",
    screenTitle: "Somatic exercises"
  },
  {
    groupIndex: 0,
    component: PeopleChosen,
    screenId: "Social_proof",
    screenTitle: "4,280,000+ users have chosen us"
  },
  {
    groupIndex: 0,
    component: MainGoal,
    headerTitle: "Goals",
    screenId: "Main_goal",
    screenTitle: "What's your main goal?"
  },
  {
    groupIndex: 0,
    component: WeKnow,
    screenId: "Feedback_weight_goal",
    screenTitle: "We know how to make it happen!"
  },
  {
    groupIndex: 0,
    component: HopeToAchieve,
    headerTitle: "Goals",
    screenId: "Goals",
    screenTitle: "What do you hope to achieve?"
  },

  {
    groupIndex: 1,
    component: TransformBody,
    screenId: "Feedback_weight_goal",
    screenTitle: "Transform your body with the power of mindful exercise"
  },
  {
    groupIndex: 1,
    component: FeelingStressed,
    headerTitle: "Emotional profile",
    screenId: "Stressed",
    screenTitle: "How often do you find yourself feeling stressed?"
  },
  {
    groupIndex: 1,
    component: FeelingExhausted,
    headerTitle: "Emotional profile",
    screenId: "Exhausted",
    screenTitle: "How frequently do you find yourself feeling exhausted?"
  },
  {
    groupIndex: 1,
    component: CryingLastTime,
    headerTitle: "Emotional profile",
    screenId: "Cried",
    screenTitle: "What was the last time you cried?"
  },
  {
    groupIndex: 1,
    component: UnressolvedEmotions,
    headerTitle: "Emotional profile",
    screenId: "Negative_emotions",
    screenTitle: "Do you carry unresolved negative emotions?"
  },
  {
    groupIndex: 1,
    component: BrainFog,
    headerTitle: "Emotional profile",
    screenId: "Brain_fog",
    screenTitle: "How frequently do you experience brain fog?"
  },
  {
    groupIndex: 1,
    component: EmotionallyAffected,
    headerTitle: "Emotional profile",
    screenId: "Affected_emotionally",
    screenTitle: "Has something recently affected you emotionally?"
  },
  {
    groupIndex: 1,
    component: LastTimeRelaxed,
    headerTitle: "Emotional profile",
    screenId: "Felt_relaxed",
    screenTitle: "When was the last time you truly felt relaxed?"
  },
  {
    groupIndex: 1,
    component: RelaseYourself,
    screenId: "Feedback_emotions",
    screenTitle: "Release yourself from the hold of stress"
  },
  {
    groupIndex: 1,
    component: StruggleSetbacks,
    headerTitle: "Emotional profile",
    screenId: "Setbacks",
    screenTitle: "Have you struggled with any of these setbacks in the past?"
  },
  {
    groupIndex: 1,
    component: PeopleGiveUp,
    screenId: "Feedback_setbacks",
    screenTitle: "Why do people give up on exercise?"
  },
  {
    groupIndex: 2,
    component: BodyImage,
    headerTitle: "Body Profile",
    screenId: "Body_image",
    screenTitle: "How satisfied are you with your body image right now?"
  },
  {
    groupIndex: 2,
    component: ChooseBodyType,
    headerTitle: "Body Profile",
    screenId: "Current_body",
    screenTitle: "Choose your current body type"
  },

  {
    groupIndex: 2,
    component: CompositionWantToHave,
    headerTitle: "Body Profile",
    screenId: "Target_body",
    screenTitle: "What body composition do you want to have?"
  },
  {
    groupIndex: 2,
    component: HowWeightChange,
    headerTitle: "Body Profile",
    mappingKey: "qqWeightFluctuation",
    screenId: "Weight_change",
    screenTitle: "How does your weight typically change?"
  },

  {
    groupIndex: 2,
    component: ReadyToLose,
    headerTitle: "Body Profile",
    screenId: "Weightloss_determination",
    screenTitle: "Are you ready to finally lose the unwanted weight?"
  },
  {
    groupIndex: 2,
    component: WithoutStress,
    headerTitle: "Body Profile",
    screenId: "Feedback_determination",
    screenTitle: "Lose excess weight without added stress"
  },
  {
    groupIndex: 2,
    component: ChooseFocus,
    headerTitle: "Body Profile",
    screenId: "Tags",
    screenTitle: "Choose your focus"
  },
  {
    groupIndex: 2,
    component: ChooseTargetZones,
    headerTitle: "Body Profile",
    screenId: "Target_zones",
    screenTitle: "Choose your target zones"
  },
  {
    groupIndex: 2,
    component: LoadingChart,
    headerTitle: "Body Profile",
    screenId: "Loading_feeling",
    screenTitle:
      "EasyFit is not just about working out, it's about feeling good on the inside and out"
  },
  {
    groupIndex: 2,
    component: SciaticaPain,
    headerTitle: "Body Profile",
    screenId: "Scatica",
    screenTitle: "Do you experience sciatica pain?"
  },
  {
    groupIndex: 2,
    component: Headaches,
    headerTitle: "Body Profile",
    screenId: "Headaches",
    screenTitle: "Do you get headaches a lot?"
  },
  {
    groupIndex: 2,
    component: SufferFromConditions,
    headerTitle: "Body Profile",
    screenId: "Conditions",
    screenTitle: "Do you suffer from any of following conditions?"
  },
  {
    groupIndex: 2,
    component: TakingMedications,
    headerTitle: "Body Profile",
    screenId: "Medications",
    screenTitle: "Are you taking any medications?"
  },
  {
    groupIndex: 2,
    component: BackProblems,
    headerTitle: "Body Profile",
    screenId: "Back_issues",
    screenTitle: "Do you have any serious back problems?"
  },
  {
    groupIndex: 2,
    component: SensitiveAreas,
    headerTitle: "Body Profile",
    screenId: "Sensitive_areas",
    screenTitle: "Do you have any sensitive areas that you want to avoid?"
  },
  {
    groupIndex: 2,
    component: ThankForSharing,
    headerTitle: "Body Profile",
    screenId: "Feedback_medical",
    screenTitle: "Thank you for sharing!"
  },
  {
    groupIndex: 2,
    component: TensionInBody,
    headerTitle: "Body Profile",
    screenId: "Tension",
    screenTitle: "Do you often feel tension in your body?"
  },
  {
    groupIndex: 2,
    component: TensionFeel,
    headerTitle: "Body Profile",
    screenId: "Tension_zones",
    screenTitle: "Where do you usually feel the most tension?"
  },
  {
    groupIndex: 2,
    component: ReleaseTension,
    headerTitle: "Body Profile",
    screenId: "Feedback_tension",
    screenTitle: "Release tension from your shoulders for full body relief"
  },
  {
    groupIndex: 3,
    component: StruggleSleep,
    headerTitle: "Activity",
    screenId: "Sleep_troubles",
    screenTitle: "Struggling to get a good night's sleep?"
  },
  {
    groupIndex: 3,
    component: SleepIsKey,
    headerTitle: "Activity",
    screenId: "Sleep_key",
    screenTitle: "Sleep is key for weight loss"
  },
  {
    groupIndex: 3,
    component: OftenWorkout,
    headerTitle: "Activity",
    mappingKey: "qqExerciseFrequency",
    screenId: "Workouts",
    screenTitle: "How often do you work out?"
  },
  {
    groupIndex: 3,
    component: YouAwesome,
    screenId: "Feedback_workouts",
    screenTitle: "We think you're awesome!"
  },
  {
    groupIndex: 3,
    component: KnowAboutSomatic,
    headerTitle: "Activity",
    screenId: "Somatic_familiarity",
    screenTitle: "How much do you know about somatic exercises?"
  },
  {
    groupIndex: 3,
    component: TriedYoga,
    headerTitle: "Activity",
    screenId: "Yoga_familiarity",
    screenTitle: "Have you tried yoga, stretching, or pilates?"
  },
  {
    groupIndex: 3,
    component: HowFlexible,
    headerTitle: "Activity",
    mappingKey: "qqFlexibility",
    screenId: "Flexible",
    screenTitle: "How flexible are you?"
  },
  {
    groupIndex: 3,
    component: BestShape,
    headerTitle: "Activity",
    screenId: "Happy_shape",
    screenTitle: "When was the last time you were in your best shape?"
  },
  {
    groupIndex: 3,
    component: YouAreAhead,
    headerTitle: "Activity",
    screenId: "Feedback_happy_shape",
    screenTitle: "You are ahead of 73% of users"
  },
  {
    groupIndex: 3,
    component: SelfCareTroubles,
    headerTitle: "Activity",
    screenId: "Self_care",
    screenTitle: "Do you have trouble making time for self-care and relaxation?"
  },
  {
    groupIndex: 3,
    component: TypicalDay,
    headerTitle: "Activity",
    mappingKey: "qqDayRoutine",
    screenId: "Typical_day",
    screenTitle: "How would you describe your typical day?"
  },
  {
    groupIndex: 3,
    component: SpareForSelfCare,
    headerTitle: "Activity",
    screenId: "Devote_self_care",
    screenTitle: "How much time can you spare for self-care each day?"
  },
  {
    groupIndex: 4,
    component: BiggestMotivation,
    headerTitle: "Almost Done",
    screenId: "Reason_to_fit",
    screenTitle: "What's your biggest motivation for getting in shape?"
  },
  {
    groupIndex: 4,
    component: Height,
    headerTitle: "Almost Done",
    screenId: "Height",
    screenTitle: "How tall are you?"
  },
  {
    groupIndex: 4,
    component: Weight,
    headerTitle: "Almost Done",
    screenId: "Weight",
    screenTitle: "What's your current weight?"
  },
  {
    groupIndex: 4,
    component: WeightGoal,
    headerTitle: "Almost Done",
    screenId: "Target_weight",
    screenTitle: "Great! What weight are you aiming for?"
  },
  {
    groupIndex: 4,
    component: Age,
    headerTitle: "Almost Done",
    mappingKey: "qqAge",
    screenId: "age",
    screenTitle: "What is your age?"
  },
  {
    groupIndex: 4,
    component: Report,
    headerTitle: "Almost Done",
    screenId: "Fitlevel_feedback",
    screenTitle: "Your current fitness level overview"
  },
  {
    groupIndex: 4,
    component: FutureEvent,
    headerTitle: "Almost Done",
    mappingKey: "qqOccasion",
    screenId: "t",
    screenTitle: "Do you have an important occasion coming up?"
  },
  {
    groupIndex: 4,
    component: EventDate,
    headerTitle: "Almost Done",
    mappingKey: "qqOccasionDate",
    screenId: "Ocasion_date",
    screenTitle: "When is this occasion?"
  }
];
