import styles from "./styles.module.scss";
import { goalMapping } from "../../utils/goalMapping";
import { Body, Dumbbell, Scale2, Target } from "@assets/Icons";
import { useSelector } from "react-redux";

export const Metrics = () => {
  const user = useSelector((state) => state.user?.userInfo);
  const bodyMassType = user?.fitnessProfile?.bodyMassType
  const fitnessLevel = user?.fitnessProfile?.fitnessLevel


  const goal = goalMapping[user?.questions?.qqGoal] || "Lose weight";
  const ms = localStorage.getItem("ms");

  const weightGoal =
    ms === "us"
      ? user?.questions?.qqGoalWeightLbs
      : user?.questions?.qqGoalWeightKg;


  return (
    <div className={styles.goals}>
      <div className={styles.goalsWrapper}>
        <div className={styles.goalItem}>
          <div className={styles.icon}>
            <Target />
          </div>
          <div className={styles.text}>
            <p>Goal</p>
            <span>{goal}</span>
          </div>
        </div>
        <div className={styles.goalItem}>
          <div className={styles.icon}>
            <Dumbbell />
          </div>
          <div className={styles.text}>
            <p>Difficulty</p>
            <span>{fitnessLevel?.toLowerCase() || "Beginner"}</span>
          </div>
        </div>
      </div>

      <div className={styles.divider}></div>
      <div className={styles.goalsWrapper}>
        <div className={styles.goalItem}>
          <div className={styles.icon}>
            <Body />
          </div>
          <div className={styles.text}>
            <p>Body type</p>
            <span>{bodyMassType?.toLowerCase()}</span>
          </div>
        </div>
        <div className={styles.goalItem}>
          <div className={styles.icon}>
            <Scale2 />
          </div>
          <div className={styles.text}>
            <p>Weight goal</p>
            <span>
              {weightGoal} {ms === "us" ? "lbs" : "kg"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
