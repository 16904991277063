import { useNavigate } from "react-router";
import { BreakdownWithImage } from "../../../../shared";
import img from "./img.png";

const paragraph = [
  "<strong>The #1 reason is starting to big too quickly.</strong>",
  "We’re not promising quick results, but we’are committed to supporting you on your journey to achieving your goals."
];

export const PeopleGiveUp = ({ onNextStep, onTrackEvent }) => {
  const navigate = useNavigate();

  const handleContinue = () => {
    onNextStep?.();
    onTrackEvent?.();
  };
  return (
    <BreakdownWithImage
      img={img}
      title="Why do people give up on exercise?"
      paragraph={paragraph}
      onContinue={handleContinue}
    />
  );
};
