import styles from "./styles.module.scss";
import heart from "@assets/img/emoji/heart.png";
import muscle from "@assets/img/emoji/muscle.png";
import dance from "@assets/img/emoji/dance.png";
import pregnant from "@assets/img/emoji/pregnant.png";
import massage from "@assets/img/emoji/massage.png";

import { IconCard } from "../../../../shared";

const data = [
  {
    value: "1",
    displayValue: "Feel more confident in my body",
    emoji: heart
  },
  {
    value: "2",
    displayValue: "Feel healthier and more energetic",
    emoji: muscle
  },
  {
    value: "3",
    displayValue: "Fit in my clothes better",
    emoji: dance
  },
  {
    value: "4",
    displayValue: "Get back in shape after giving birth",
    emoji: pregnant
  },

  {
    value: "5",
    displayValue: "Other",
    emoji: massage
  }
];
export const BiggestMotivation = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (v) => {
    onNextStep(v.value);
    onTrackEvent?.(v.displayValue);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>What’s your biggest motivation for getting in shape?</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCard
              onClick={() => handleNextStep(value)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
