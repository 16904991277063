import styles from "./styles.module.scss";
import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";
import img5 from "./img/5.png";
import img6 from "./img/6.png";
import { WorkoutsSlider } from "../workouts";
import like from "@assets/img/emoji/like.png";
import massage from "@assets/img/emoji/massage.png";
import sleepy from "@assets/img/emoji/sleepy.png";
import backissue from "@assets/img/emoji/backissue.png";

export const Features = () => {
  const featuresBeforeSlider = [
    {
      title: "Create your personal day-to-day plan",
      description:
        "Losing weight is a complicated problem. Get a personal step-by-step action plan on day-to-day basis to help you succeed (backed by science).",
      image: img1,
      isReversed: false,
    },
    {
      title: "Weight loss plan is based on your needs and parameters",
      description:
        "An all-in-one customer service platform that helps you balance everything your customers need to be happy.",
      image: img2,
      isReversed: true,
    },
    {
      title: "Highlights of your AI‑optimized plan",
      description: [
        {
          img: like,
          value:
            "Personalized workouts to break the plateau and finally lose unwanted weight.",
        },
        {
          img: backissue,
          value:
            "Reduced tension in your hips, back, sciatica, shoulders, neck and jaw.",
        },
        {
          img: massage,
          value: "Reduced stress & worry and feel more relaxed and calm.",
        },
        {
          img: sleepy,
          value:
            "Improved sleep quality, allowing for better recovery and enhanced performance.",
        },
      ],
      image: img3,
      isReversed: false,
    },
  ];

  const featuresAfterSlider = [
    {
      title: "Never get bored",
      description:
        "Constantly repeating the same exercises can be tedious, and, let’s be honest, it doesn’t contribute to workout motivation, so you are likely to neglect your workout plan." +"\n" + "Luckily, workout library builds various workouts based on your input. ",
      image: img4,
      isReversed: false,
    },
    {
      title: "Amazing workout experience",
      description:
        "Videos with working muscles highlighted, audio guides, smart device support, exercise instructions, and many other features to enjoy the workout process.",
      image: img5,
      isReversed: true,
    },
    {
      title: "Logging food is as satisfying as eating it",
      description:
        "Keep on track your food diary with a food recognition and a barcode scanner. It's never been so simple to log what you eat.",
      image: img6,
      isReversed: false,
    },
  ];

  const renderFeatureBlocks = (features) => {
    return features.map((feature, index) => (
      <div key={index} className={styles.featureBlock}>
        <div
          className={`${styles.content} ${
            feature.isReversed ? styles.reversed : ""
          }`}
        >
          <div className={styles.text}>
            <div className={styles.textContent}>
              <h2>{feature.title}</h2>
              {Array.isArray(feature.description) ? (
                <ul className={styles.featuresItemList}>
                  {feature.description.map(({ img, value }) => (
                    <li key={value}>
                      <div className={styles.emoji}>
                        <img src={img} alt="like" />
                      </div>
                      <p>{value}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>{feature.description}</p>
              )}
            </div>
          </div>
          <div className={styles.imageContent}>
            <img src={feature.image} alt={feature.title} />
          </div>
        </div>
      </div>
    ));
  };

  return (
    <section className={styles.features}>
      {renderFeatureBlocks(featuresBeforeSlider)}
      <WorkoutsSlider />
      {renderFeatureBlocks(featuresAfterSlider)}
    </section>
  );
};
