import styles from "./styles.module.scss";

import unsure from "@assets/img/emoji/unsure.png";
import muscle from "@assets/img/emoji/muscle.png";
import thinking from "@assets/img/emoji/thinking.png";

import { useNavigate } from "react-router";
import { IconCard, SOMATIC_KEYS } from "../../../../shared";
import { useGetUserInfo } from "@hooks/useGetUserProfile";
import { formatDate } from "@utils/formatDate";
import { trackUserEvent } from "@utils/updateDataLayer";

const data = [
  {
    value: "I believe I can do it!",
    displayValue: "I believe I can do it!",
    emoji: muscle
  },
  {
    value: "I'm uncertain, but willing to try!",
    displayValue: "I'm uncertain, but willing to try!",
    emoji: thinking
  },
  {
    value: "I'm still really unsure",
    displayValue: "I'm still really unsure",
    emoji: unsure
  }
];
export const Confident = () => {
  const ms = localStorage.getItem("ms");
  const navigate = useNavigate();
  const { user } = useGetUserInfo();

  const goal =
    ms === "us"
      ? user?.questions?.qqGoalWeightLbs
      : user?.questions?.qqGoalWeightKg;

  const handleNextStep = (value) => {
    localStorage.setItem(SOMATIC_KEYS.confident, JSON.stringify(value));
    navigate("/plan-chart");
    trackUserEvent(value, {
      screenId: "Confident",
      screenTitle: "How confident are you in reaching your goal?"
    });
  };

  const getTitle = () => {
    if (!goal) {
      return "How confident are you in reaching your goals?";
    }
    return (
      <>
        How confident are you <br /> in reaching{" "}
        <span>
          {goal} {ms === "us" ? "lbs" : "kg"} <br /> by{" "}
          {formatDate(user.fitnessProfile.weightGoalCompletionEstimatedDate)}?
        </span>
      </>
    );
  };

  return (
    <div className={styles.container} id="breakdown">
      <div className={styles.content}>
        <h2>{getTitle()}</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCard
              onClick={() => handleNextStep(value.value)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
