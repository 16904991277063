import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./styles.module.scss";
import review_stars from "@assets/img/png/review_stars.png";

const reviews = [
  {
    name: "Catherine",
    country: "USA",
    date: "17.02.2025",
    title: "I love the app!",
    text: "I'm pleased and surprised by the variety of exercises. It's been 2 weeks and I already see a difference in my legs and abs! As a beginner I find instructional videos clear and motivating."
  },
  {
    name: "Sofia",
    country: "UK",
    date: "25.01.2025",
    title: "Highly recommend!",
    text: "The app is user-friendly, and I've enjoyed tracking my progress. The variety of classes keeps it interesting, and I've learned so much about fitness and health."
  },
  {
    name: "Aanya",
    country: "India",
    date: "11.02.2025",
    title: "Incredible journey!",
    text: "I’ve been using the app for a month and the progress is unreal! The guided workouts are perfect for someone like me who's just starting, and the mindfulness exercises have helped improve my overall mental health."
  },
  {
    name: "Charlotte",
    country: "Australia",
    date: "10.12.2024",
    title: "Life-changing!",
    text: "This app has completely changed my fitness routine! The personalized workouts at the highest level all motivate me every day."
  },
  {
    name: "Olivia",
    country: "Canada",
    date: "15.11.2024",
    title: "Fantastic experience!",
    text: "The community support is amazing! I appreciate the daily challenges and how they keep me engaged. The nutrition guidance has been a game-changer for my diet."
  }
];

export const ReviewSlider = () => {
  const settings = {
    dots: true,
    dotsClass: `slick-dots ${styles.dots}`,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <Slider {...settings}>
      {reviews.map((review, index) => (
        <div key={index} className={styles.review}>
          <div className={styles.stars}>
            <img src={review_stars} alt="Review stars" />
          </div>
          <div className={styles.name}>
            <p>
              {review.name}, {review.country}
            </p>{" "}
            {review.date}
          </div>
          <div className={styles.title}>
            <p>{review.title}</p>
          </div>
          <div className={styles.paragraph}>{review.text}</div>
        </div>
      ))}
    </Slider>
  );
};
