import { getYatX } from "@utils/getYatX";
import styles from "../styles.module.scss";

const pathD =
  "M38 32C71.6729 32 48.2483 58.4892 136.091 96.9707C203.925 122.958 211.733 203.919 323 200.919";

export const ChartLoseWeight = ({ x }) => {
  const point = getYatX(x, pathD);

  return (
    <svg
      width="343"
      height="241"
      viewBox="0 0 343 241"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_66_4195)">
        <rect width="343" height="241" fill="#F8F8F8" />
        <rect
          className={styles.show}
          x="283"
          y="131"
          width="55"
          height="44"
          rx="7"
          fill="#7C8AF7"
        />
        <path
          className={styles.show}
          d="M329 175L322.23 182.222C321.834 182.643 321.166 182.643 320.77 182.222L314 175H329Z"
          fill="#7C8AF7"
        />
        {point && (
          <line
            x1={point?.x}
            y1={point?.y}
            x2={point?.x}
            y2="202"
            opacity="0.2"
            stroke="#171717"
            strokeWidth="1"
            strokeDasharray="5,5"
          />
        )}
        <rect
          width="293"
          height="1"
          transform="matrix(-1 0 0 1 330 31)"
          fill="#D9D9D9"
        />
        <rect
          width="293"
          height="1"
          transform="matrix(-1 0 0 1 330 116)"
          fill="#D9D9D9"
        />
        <rect
          width="293"
          height="1"
          transform="matrix(-1 0 0 1 330 201)"
          fill="#D9D9D9"
        />
        <path
          className={styles.showOpacity}
          opacity="0.2"
          d="M38 32C71.6729 32 48.2483 58.4892 136.091 96.9707C201.451 122.01 211.084 198.087 311.231 200.919H323C318.942 201.028 315.022 201.026 311.231 200.919H38V32Z"
          fill="url(#paint0_linear_66_4195)"
        />
        <path
          className={styles.grow}
          d={pathD}
          stroke="url(#paint1_linear_66_4195)"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g filter="url(#filter0_d_66_4195)">
          <circle
            className={styles.show}
            cx="322"
            cy="201"
            r="5"
            fill="white"
            shapeRendering="crispEdges"
          />
          <circle
            className={styles.show}
            cx="322"
            cy="201"
            r="6"
            stroke="black"
            strokeOpacity="0.16"
            strokeWidth="2"
            shapeRendering="crispEdges"
          />
        </g>

        {point && (
          <g filter="url(#filter0_d_66_4196)">
            <circle
              cx={point?.x}
              cy={point.y}
              r="5"
              fill="white"
              shapeRendering="crispEdges"
              className={styles.show}
            />
            <circle
              cx={point?.x}
              cy={point?.y}
              r="6"
              stroke="black"
              strokeOpacity="0.16"
              strokeWidth="2"
              shapeRendering="crispEdges"
              className={styles.show}
            />
          </g>
        )}
      </g>

      <defs>
        <filter
          id="filter0_d_66_4195"
          x="307"
          y="186"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_66_4195"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_66_4195"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_66_4195"
          x1="181"
          y1="32"
          x2="181"
          y2="201"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F91E1E" />
          <stop offset="0.354356" stopColor="#FFA51F" stopOpacity="0.72" />
          <stop offset="0.576395" stopColor="#F9ED2A" stopOpacity="0.64" />
          <stop offset="0.847776" stopColor="#64EC82" stopOpacity="0.32" />
          <stop offset="1" stopColor="#36E6E6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_66_4195"
          x1="38"
          y1="116.5"
          x2="323"
          y2="116.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F91E1E" />
          <stop offset="0.26" stopColor="#FFA51F" />
          <stop offset="0.465" stopColor="#F9ED2A" />
          <stop offset="0.725" stopColor="#64EC82" />
          <stop offset="1" stopColor="#36E6E6" />
        </linearGradient>
        <clipPath id="clip0_66_4195">
          <rect width="343" height="241" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
