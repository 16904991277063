import React, { useState } from "react";
import Slider from "react-slick";
import styles from "./styles.module.scss";
import balance from "./slider/img/balance.png";
import tightness from "./slider/img/tightness.png";
import stubborn from "./slider/img/stubborn.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderData = [
  {
    image: stubborn,
    title: "Lose stubborn fat",
    text: " Stress, worry and emotional burdens can sabotage weight loss efforts. This plan provides you with a well-rounded approach to relieve stored emotions and finally get your dream body.",
  },
  {
    image: balance,
    title: "Restore emotional balance",
    text: "Try out our transformative workout exercises to find inner peace, and let go of overwhelming stress, worry, and emotional weight.",
  },
  {
    image: tightness,
    title: "Reduce muscle tightness",
    text: "Use simple poses and stretches to alleviate tension in your muscles, including the hips, back, neck, shoulders, and more.",
  },
];

const CustomNextArrow = ({ onClick }) => (
  <div className={styles.slickNext} onClick={onClick}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="32"
        height="32"
        rx="16"
        transform="matrix(-1 0 0 1 32 0)"
        fill="white"
      />
      <path
        d="M9.98828 15.9928C9.98828 15.4408 10.4353 14.9938 10.9873 14.9938C11.4653 14.9938 17.0523 14.9938 18.5423 14.9938L15.5763 11.9968L16.9813 10.5918L21.6953 15.2748C21.8913 15.4698 21.9883 15.7318 21.9883 15.9938C21.9883 16.2548 21.8903 16.5158 21.6953 16.7108L16.9813 21.3938L15.5763 19.9888L18.5423 16.9918C17.0523 16.9918 11.4653 16.9918 10.9873 16.9918C10.4353 16.9918 9.98828 16.5448 9.98828 15.9928Z"
        fill="black"
      />
    </svg>
  </div>
);

const CustomPrevArrow = ({ onClick }) => (
  <div className={styles.slickPrev} onClick={onClick}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="white" />
      <path
        d="M22.0117 15.9928C22.0117 15.4408 21.5647 14.9938 21.0127 14.9938C20.5347 14.9938 14.9477 14.9938 13.4577 14.9938L16.4237 11.9968L15.0187 10.5918L10.3047 15.2748C10.1087 15.4698 10.0117 15.7318 10.0117 15.9938C10.0117 16.2548 10.1097 16.5158 10.3047 16.7108L15.0187 21.3938L16.4237 19.9888L13.4577 16.9918C14.9477 16.9918 20.5347 16.9918 21.0127 16.9918C21.5647 16.9918 22.0117 16.5448 22.0117 15.9928Z"
        fill="black"
      />
    </svg>
  </div>
);

export const ReliefSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    dotsClass: `slick-dots ${styles.dots}`,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
  };

  return (
    <div className={styles.container}>
      <h2>Find lasting relief in 10-15 min a day</h2>
      <div className={styles.sliderWrapper}>
        <Slider {...settings}>
          {sliderData.map((slide, index) => (
            <React.Fragment key={index}>
              <div className={styles.slide}>
                <img src={slide.image} alt="Before and After" />
                <div className={styles.titleBlock}>{slide.title}</div>
              </div>
              <div className={styles.description}>
                <p>{slide.text}</p>
              </div>
            </React.Fragment>
          ))}
        </Slider>
      </div>
      <p className={styles.disclaimer}>
        *Disclaimer: In order to achieve the best possible results, it's crucial
        to consistently exercise and follow a balanced diet. We encourage you to
        consult with a healthcare professional before starting any exercise
        program.
      </p>
    </div>
  );
};
