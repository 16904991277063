import { useState } from "react";
import styles from "./styles.module.scss";
import muscle from "@assets/img/emoji/muscle.png";
import handstand from "@assets/img/emoji/handstand.png";
import yoga from "@assets/img/emoji/yoga.png";
import run from "@assets/img/emoji/run.png";

import { SOMATIC_KEYS } from "../../../../shared";

import { IconCardCheckbox } from "../../../../shared";
import { Button } from "../../../../../../common";

const data = [
  {
    value: "Build muscle",
    displayValue: "Build muscle",
    emoji: muscle
  },
  {
    value: "Become more flexible",
    displayValue: "Become more flexible",
    emoji: handstand
  },
  {
    value: "Enhance mental health",
    displayValue: "Enhance mental health",
    emoji: yoga
  },
  {
    value: "Develop endurance",
    displayValue: "Develop endurance",
    emoji: run
  }
];
export const HopeToAchieve = ({ onNextStep, onTrackEvent }) => {
  const [checked, setChecked] = useState(() => {
    const saved = localStorage.getItem(SOMATIC_KEYS.hopeToAchieve);
    return saved ? JSON.parse(saved) : [];
  });

  const handleCheck = (value) => {
    setChecked((prevState) => {
      const newState = prevState.includes(value)
        ? prevState.filter((item) => item !== value)
        : [...prevState, value];

      localStorage.setItem(
        SOMATIC_KEYS.hopeToAchieve,
        JSON.stringify(newState)
      );
      return newState;
    });
  };

  const handleNextStep = () => {
    onNextStep(checked);
    onTrackEvent(checked);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>What do you hope to achieve?</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCardCheckbox
              checked={checked.includes(value.value)}
              onClick={() => handleCheck(value.value)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
      <div className={styles.action}>
        <Button disabled={!checked.length} onClick={handleNextStep}>
          Continue
        </Button>
      </div>
    </div>
  );
};
