import styles from "./styles.module.scss";

import { IconCardCheckbox } from "../../../../shared";

import { useState } from "react";
import { Button } from "../../../../../../common";

const data = [
  {
    value: "Vitamins  or supplements",
    displayValue: "Vitamins  or supplements"
  },
  {
    value: "Hormones",
    displayValue: "Hormones"
  },
  {
    value: "Antidepressants",
    displayValue: "Antidepressants"
  },
  {
    value: "Cardiovascular medications",
    displayValue: "Cardiovascular medications"
  },
  {
    value: "Antidiabetic",
    displayValue: "Antidiabetic"
  },
  {
    value: "Antibiotics",
    displayValue: "Antibiotics"
  },
  {
    value: "Other",
    displayValue: "Other"
  },
  {
    value: "None of the above",
    displayValue: "None of the above"
  }
];
export const TakingMedications = ({ onNextStep, onTrackEvent }) => {
  const [checked, setChecked] = useState([]);

  const handleCheck = (value) => {
    setChecked((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((item) => item !== value);
      }
      return [...prevState, value];
    });
  };

  const handleNextStep = () => {
    onNextStep(checked);
    onTrackEvent?.(checked);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Are you taking any medications?</h2>
        <div className={styles.cardsWithImage}>
          <div className={styles.cards}>
            {data.map((value) => (
              <IconCardCheckbox
                fullWidth
                checked={checked.includes(value.value)}
                onClick={() => handleCheck(value.value)}
                title={value.displayValue}
                key={value.value}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.action}>
        <Button disabled={!checked.length} onClick={handleNextStep}>
          Continue
        </Button>
      </div>
    </div>
  );
};
