export const updateDataLayer = (obj) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};

export const trackUserEvent = (answer, currentStep) => {
  let selectedOption = "";
  if (answer !== undefined && answer !== null) {
    if (Array.isArray(answer)) {
      selectedOption = { options: answer };
    } else if (typeof answer === "string" || typeof answer === "number") {
      selectedOption = answer.toString();
    }
  }

  updateDataLayer({
    event: "continue_tap",
    screen_name: currentStep?.screenId || "",
    screen_title: currentStep?.screenTitle || "",
    selected_option: selectedOption
  });
  console.log(
    "continue_tap events:",
    window.dataLayer.filter((event) => event.event === "continue_tap")
  );
};
