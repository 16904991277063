import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import average from "./img/average.png";
import toned from "./img/toned.png";
import lean from "./img/lean.png";
import few_pounds_less from "./img/few_pounds_less.png";
import { capitalizeFirstLetter } from "@utils/formatProfile";

const COMPOSITIONS = {
  Lean: {
    label: "Lean",
    image: lean
  },
  Toned: {
    label: "Toned",
    image: toned
  },
  Average: {
    label: "Average",
    image: average
  },
  SlightlyLighter: {
    label: "Slightly lighter",
    image: few_pounds_less
  }
};
export const ProfileCard = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const ms = localStorage.getItem("ms");
  const questions = userInfo?.questions;

  const weightGoal =
    ms === "us" ? questions?.qqGoalWeightLbs : questions?.qqGoalWeightKg;

  const storedGoals = JSON.parse(localStorage.getItem("hopeToAchieve")) || [];
  const targetBodyLocalStorage =
    JSON.parse(localStorage.getItem("targetBody")) || "Toned";
  const targetBody = COMPOSITIONS[targetBodyLocalStorage]?.label;

  const defaultGoal = "Reduce stress and anxiety";
  const goalText = storedGoals.length ? formatGoals(storedGoals) : defaultGoal;

  function formatGoals(goals) {
    if (goals.length === 1) return capitalizeFirstLetter(goals[0]);
    return capitalizeFirstLetter(
      goals.slice(0, -1).join(", ") + " and " + goals[goals.length - 1]
    );
  }

  const bodyImage = COMPOSITIONS[targetBodyLocalStorage]?.image || average;

  return (
    <div className={styles.profileCard}>
      <h2>This plan is based on your needs and parameters:</h2>
      <div className={styles.wrapper}>
        <div className={styles.profileChart}>
          <div className={styles.clouds}>
            <div className={styles.cloud}>
              <p>Weight goal</p>{" "}
              <h5>
                {weightGoal} {ms === "us" ? "lbs" : "kg"}
              </h5>
            </div>
            <div className={styles.cloud}>
              <p>Target body</p> <h5>{targetBody}</h5>
            </div>
            <div className={styles.cloud}>
              <p>Age</p> <h5>{questions?.qqAge}</h5>
            </div>
          </div>
          <img
            src={bodyImage}
            className={styles.bodyImg}
            alt={`${targetBody} body type`}
          />
          <div className={styles.goal}>
            <div className={styles.goalCloud}>{goalText}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
