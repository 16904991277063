import { Button } from "@common/index";
import styles from "./styles.module.scss";
import eighteen from "./img/18-29.png";
import fourty from "./img/40-49.png";
import fifty from "./img/50+.png";

const data = {
  "18-29": eighteen,
  "30-39": eighteen,
  "40-49": fourty,
  "50+": fifty
};

export const PeopleChosen = ({ onNextStep, onTrackEvent }) => {
  const value = localStorage.getItem("ageStart");
  const handleNextStep = () => {
    onNextStep();
    onTrackEvent();
  };
  return (
    <div className={styles.container} id="breakdown">
      <div className={styles.title}>
        <h1>4,280,000+</h1>
        <p>users have chosen us</p>
      </div>
      <div className={styles.image}>
        <img src={data[value]} alt="bg" />
      </div>
      <div className={styles.action}>
        <Button onClick={handleNextStep}>Continue</Button>
      </div>
    </div>
  );
};
