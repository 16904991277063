import styles from "./styles.module.scss";
import { IconCard } from "src/routes/onboarding/shared";
import vacation from "@assets/img/emoji/vacation.png";
import wedding from "@assets/img/emoji/wedding.png";
import holiday from "@assets/img/emoji/holiday.png";
import sportingevent from "@assets/img/emoji/sportingevent.png";
import reunion from "@assets/img/emoji/reunion.png";
import birthday from "@assets/img/emoji/birthday.png";
import other from "@assets/img/emoji/other.png";
import no from "@assets/img/emoji/no.png";

export const DATA = [
  {
    value: "VACATION",
    displayValue: "Vacation",
    icon: vacation
  },
  {
    value: "WEDDING",
    displayValue: "Wedding",
    icon: wedding
  },
  {
    value: "HOLIDAY",
    displayValue: "Holiday",
    icon: holiday
  },
  {
    value: "SPORT_EVENT",
    displayValue: "Sporting event",
    icon: sportingevent
  },
  {
    value: "REUNION",
    displayValue: "Reunion",
    icon: reunion
  },
  {
    value: "BIRTHDAY",
    displayValue: "Birthday",
    icon: birthday
  },
  {
    value: "OTHER",
    displayValue: "Other",
    icon: other
  },
  {
    value: null,
    displayValue: "No events anytime soon",
    icon: no
  }
];

export const FutureEvent = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (value, displayValue) => {
    onNextStep(value);
    onTrackEvent?.(displayValue);
  };

  return (
    <div className={styles.container}>
      <h2>Do you have an important occasion coming up?</h2>
      <p className={styles.hint}>
        Having an event you're excited about can be an incredible motivator
      </p>
      <div className={styles.cards}>
        {DATA.map(({ value, displayValue, icon }) => (
          <IconCard
            onClick={() => handleNextStep(value, displayValue)}
            img={icon}
            title={displayValue}
            key={value}
          />
        ))}
      </div>
    </div>
  );
};
