import { CircularProgressbar } from "react-circular-progressbar";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { trackUserEvent } from "@utils/updateDataLayer";
import { ReviewSlider } from "src/routes/onboarding/shared/components/reviewsSlider";

export const CreatingPlan = () => {
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 1;
        } else {
          clearInterval(interval);
          trackUserEvent("", {
            screenId: "Creating",
            screenTitle: "Creating your personalized plan"
          });
          navigate("/name");
          return 100;
        }
      });
    }, 50);
    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div className={styles.container} id="breakdown">
      <div className={styles.progress}>
        <CircularProgressbar
          strokeWidth={4}
          styles={{
            trail: {
              color: "#F8F8F8",
              stroke: "#F8F8F8"
            },
            root: {
              borderRadius: "50%",
              backgroundColor: "white"
            },
            path: {
              stroke: "var(--primary)",
              color: "var(--primary)"
            },
            text: {
              fontWeight: "700",
              fill: "black",
              fontSize: "18px"
            }
          }}
          value={progress}
          text={`${progress}%`}
        />
      </div>
      <div className={styles.subtitle}>Creating your personalized plan</div>
      <div className={styles.reviewContainer}>
        <div className={styles.people}>
          <h2>150 million people</h2>
          <p>Become the one to choose a healthy lifestyle with EasyFit</p>
        </div>
        <ReviewSlider />
      </div>
    </div>
  );
};
