import styles from "./styles.module.scss";
import logo from "../../../assets/img/png/logo_EasyFit.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../common";
import { Login } from "../../../assets/Icons";

export const Header = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const toggleBurger = () => {
    setOpen(!open);
  };

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <img src={logo} alt="Logo" />
        <nav
          className={
            open
              ? `${styles.navigation}  ${styles.open}`
              : `${styles.navigation} ${styles.close}`
          }
        >
          <ul>
            <li>
              <Link to="/terms-of-use">Terms of Use</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/subscription-policy">Subscription Policy</Link>
            </li>
          </ul>
          <div className={styles.loginNav}>
            <Button onClick={() => navigate("/auth")}>
              Login <Login />
            </Button>
          </div>
          <div className={styles.contact}>
            Contact us:{" "}
            <a href="mailto:support@easyfit.me">support@easyfit.me</a>
          </div>
        </nav>
        <div className={styles.login}>
          <Button onClick={() => navigate("/auth")}>
            <Login /> Login
          </Button>
        </div>
        <div
          className={
            open ? `${styles.burger} ${styles.open}` : `${styles.burger}`
          }
          onClick={toggleBurger}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </header>
  );
};
