import { RightArrow } from "@assets/Icons";
import { Button } from "@common/button";
import styles from "./styles.module.scss";

export const ArrowButton = ({ isValid, handleNextStep }) => {
  return (
    <div>
      <Button
        className={`${styles.arrowButton} ${isValid ? styles.valid : ""}`}
        onClick={handleNextStep}
        disabled={!isValid}
      >
        <RightArrow />
      </Button>
    </div>
  );
};
