import { useState } from "react";

import styles from "./styles.module.scss";
import amanda from "./img/amanda.png";
import emily from "./img/emily.png";
import lisa from "./img/lisa.png";

const SLIDER_DATA = [
  {
    img: amanda,
    name: "Amanda",
    result: "-8 kg",
    text: "I've struggled with my weight for years, but with support from the app I felt inspired. The tailored workout plans and easy-to-follow exercises kept me motivated and on track. I'm thrilled with my results and feel more confident and healthier than ever!",
  },
  {
    img: emily,
    name: "Emily",
    text: "The variety of workouts, from yoga to higher intensity, keeps things interesting and fun. I've seen improvements in my stamina, flexibility, and overall health.",
    result: "-7 kg",
  },
  {
    img: lisa,
    name: "Lisa",
    result: "-13 kg",
    text: "The guided exercises and stress-relief workouts are fantastic. I feel more balanced and less stressed since incorporating these routines.",
  },
];

const truncateText = (text, limit) => {
  if (text.length <= limit) return text;

  const words = text.split(" ");
  let truncatedText = "";

  for (let word of words) {
    if (truncatedText.length + word.length + 1 > limit) break;
    truncatedText += `${word} `;
  }

  return truncatedText.trim() + "...";
};

export const Results = () => {
  const [expanded, setExpanded] = useState([]);

  const handleExpand = (index) => {
    if (expanded.includes(index)) {
      setExpanded((prevState) => prevState.filter((item) => item !== index));
      return;
    }
    setExpanded((prevState) => [...prevState, index]);
  };

  const getIsExpanded = (index) => {
    return expanded.includes(index);
  };

  const textLimit = 149;

  return (
    <section className={styles.results}>
      <div className={styles.resultsContent}>
        <h2>Results that make us proud</h2>
        <div className={styles.cards}>
          {SLIDER_DATA.map(({ text, img, name, result }, index) => {
            const isExpanded = getIsExpanded(index);
            return (
              <div
                className={styles.card}
                key={index}
              >
                <img src={img} alt={name} />
                <p className={styles.name}>
                  {name}, <span>{result}</span>
                </p>
                <div className={styles.text}>
                  {isExpanded || text.length <= textLimit
                    ? text
                    : truncateText(text, textLimit)}
                </div>
                {text.length > textLimit && (
                  <span
                    className={styles.readMore}
                    onClick={() => handleExpand(index)}
                  >
                    {isExpanded ? "Read less" : "Read more"}
                  </span>
                )}
              </div>
            );
          })}
        </div>
        <p className={styles.disclaimer}>
          *Disclaimer: In order to achieve the best possible results, it's
          crucial to consistently exercise and follow a balanced diet. We
          encourage you to consult with a healthcare professional before
          starting any exercise program.
        </p>
      </div>
    </section>
  );
};
