import { BreakdownWithImage, SOMATIC_KEYS } from "../../../../shared";
import img from "./img.png";

export const SleepIsKey = ({ onNextStep, onTrackEvent }) => {
  const readyToLoseAnswer = localStorage.getItem(SOMATIC_KEYS.weightChange);
  const isStruggleToGain = readyToLoseAnswer === "STRUGGLE_WITH_WEIGHT_GAIN";

  const title = isStruggleToGain
    ? "Sleep is key for getting fit and toned"
    : "Sleep is key for weight loss";

  const paragraphText = isStruggleToGain
    ? "getting fit and toned"
    : "weight loss";

  const paragraph = [
    "Good sleep helps improve metabolism, control appetite, and reduce cravings, and it gives you more energy.",
    `By supporting hormonal health and digestion along with ${paragraphText}, somatic workouts can help you <strong>get a good night's rest.</strong>
    </br>
    </br>
    <span>*Information sourced from "National Library of medicine"</span>`
  ];
  return (
    <BreakdownWithImage
      title={title}
      paragraph={paragraph}
      img={img}
      onContinue={() => {
        onNextStep();
        onTrackEvent?.();
      }}
    />
  );
};
