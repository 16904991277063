import {
  CoffeColdPressOne,
  CoffeeCupHot,
  CoffeeMachineOne,
  WaterBottle
} from "@assets/Icons";
import { IconCard } from "@common/iconCard";
import styles from "./styles.module.scss";

const DATA = {
  NO_WATER: {
    title: "I only drink coffee or tea",
    icon: CoffeeCupHot
  },
  TWO_GLASSES: {
    title: "About 2 glasses (16 oz)",
    icon: WaterBottle
  },
  TWO_TO_SIX_GLASSES: {
    title: "2 to 6 glasses (16-48 oz)",
    icon: CoffeColdPressOne
  },
  MORE_THAN_SIX_GLASSES: {
    title: "More than 6 glasses",
    icon: CoffeeMachineOne
  }
};

export const WaterIntake = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (key, title) => {
    console.log(title);
    onNextStep(key);
    onTrackEvent(title);
  };

  return (
    <div className={styles.container}>
      <h2>What your daily water intake?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([key, { title, icon: Icon }]) => (
          <IconCard
            onClick={() => handleNextStep(key, title)}
            img={<Icon />}
            title={title}
            key={key}
          />
        ))}
      </div>
    </div>
  );
};
