import { ChargeFour, ChargeThree, ChargeTwo, ChargeOne } from "@assets/Icons";
import { IconCard } from "@common/iconCard";
import styles from "./styles.module.scss";

const DATA = {
  ALERT_AND_VIBRANT: {
    title: "Alert and vibrant",
    icon: ChargeOne
  },
  DROP_IN_AFTERNOON: {
    title: "A drop of energy in the afternoon",
    icon: ChargeTwo
  },
  POST_MEAL_SUMP: {
    title: "Post meal slump",
    icon: ChargeThree
  },
  TIRED: {
    title: "Tired, experiencing fatigue",
    icon: ChargeFour
  }
};

export const EnergyLevels = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (key, title) => {
    console.log(title);
    onNextStep(key);
    onTrackEvent(title);
  };

  return (
    <div className={styles.container}>
      <h2>What about your energy levels?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([key, { title, icon: Icon }]) => (
          <IconCard
            onClick={() => handleNextStep(key, title)}
            img={<Icon />}
            title={title}
            key={key}
          />
        ))}
      </div>
    </div>
  );
};
