import {
  AmericanFootbal,
  AnimalDrumsticks,
  Reward,
  Star,
  Travel,
  TravelVacationHolidayUmbrella,
  Users,
  WineGlass
} from "@assets/Icons";
import { IconCard } from "@common/iconCard";
import styles from "./styles.module.scss";

export const DATA = [
  {
    value: "VACATION",
    displayValue: "Vacation",
    icon: <Travel />
  },
  {
    value: "WEDDING",
    displayValue: "Wedding",
    icon: <WineGlass />
  },
  {
    value: "HOLIDAY",
    displayValue: "Holiday",
    icon: <TravelVacationHolidayUmbrella />
  },
  {
    value: "SPORT_EVENT",
    displayValue: "Sport event",
    icon: <AmericanFootbal />
  },
  {
    value: "REUNION",
    displayValue: "Reunion",
    icon: <Users />
  },
  {
    value: "BIRTHDAY",
    displayValue: "Birthday",
    icon: <Reward />
  },
  {
    value: "OTHER",
    displayValue: "Other",
    icon: <Star />
  },
  {
    value: null,
    displayValue: "No events anytime soon",
    icon: <AnimalDrumsticks />
  }
];

export const FutureEvent = ({ onNextStep, onTrackEvent }) => {
  const handleNextStep = (value, displayValue) => {
    console.log(value);
    onNextStep(value);
    onTrackEvent(displayValue);
  };

  return (
    <div className={styles.container}>
      <h2>Do you have an important occasion coming up?</h2>
      <p className={styles.hint}>
        Having an event you're excited about can be an incredible motivator
      </p>
      <div className={styles.cards}>
        {DATA.map(({ value, displayValue, icon }) => (
          <IconCard
            onClick={() => handleNextStep(value, displayValue)}
            img={icon}
            title={displayValue}
            key={value}
          />
        ))}
      </div>
    </div>
  );
};
